import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router, private toastr: ToastrService, private authService: AuthService, private loaderService: LoaderService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        console.log(response)  
        
        
        switch (response.status) {
        
          case 400:
            const validationErrors = response.error.errors;
          // Display each validation error using Toastr
          for (const fieldName in validationErrors) {
            if (validationErrors.hasOwnProperty(fieldName)) {
              const errorMessage = validationErrors[fieldName];
              this.toastr.error(errorMessage, fieldName);
            }
          }
            //this.toastr.error(response.status + ' Bad Request');
            // const errorMessage = this.extractErrorMessage(response.error);
            // if (response.error.messages) {
            //   this.toastr.error(response.error.errors.name);
            // }
            // else {
            //   this.toastr.error(errorMessage);
            // }
            break;
          case 401:
            this.toastr.error('Un Authorized!',response.error.exception);
            break;
          case 403:
            this.toastr.error(response.error.errors.name);
            break;
          case 404:
            this.toastr.error('Not Found!', response.error.exception);
            break;
          case 500:
            const validationErrors500 = response.error.messages;
          // Display each validation error using Toastr
          for (const fieldName in validationErrors500) {
            if (validationErrors500.hasOwnProperty(fieldName)) {
              const errorMessage = validationErrors500[fieldName];
              this.toastr.error(errorMessage);
            }
          }
            //this.toastr.error('Something Went Wrong', response.error.exception);
            break;
          default:
            if (response.status === 0) {
              this.toastr.error('Unable to Connect to the Server.');
              break;
            }
            this.toastr.error('Something Went Wrong', response.error.exception);
            break;
        }
        
        this.loaderService.loading = false;
        return throwError(response.error);
      })
      
      

    );
    
  }
  private extractErrorMessage(error: HttpErrorResponse): string {
    // Extract error message from the response body
    if (error.error && error.error.message) {
      return error.error.message;
    }

    // Fallback to a generic error message if the response body doesn't contain a message
    return 'An error occurred';
  }
}
