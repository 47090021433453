


export class GetUserProfile {
  id: string = '';
  firstName: string = '';
  lastName: string = '';
  userName: string = ''
  email: string = '';
  phoneNumber: string = '';
  isActive: string = '';
  imageUrl: string = '';
  surname: string = '';
  gender: string = '';
  dateOfBirth;
  enrollmentDate;
  nationality: string = '';
  passport: string = '';
  aadharNo: string = '';
  healthAllergies: string = '';
  bloodGroupID: string = '';
  additionalInformation: string = '';
  smsContactNumber2: string = '';
  smsContactNumber: string = '';
  otherContactNo: string = '';
  address: string = '';
}

export class ApplicationUserRoleDto {
  roleId: string = '';
  applicationRole: RolesResponseDto = new RolesResponseDto();
}

export class RolesResponseDto {
  id: string = '';
  identifier: string = '';
  name: string = '';
}

export enum Gender {
  Male = "0",
  Female = "1",
  others = "2"
}
export class classSystemType {
  id: string;
  name: string;
  IsActive: boolean = true;
  Description: string = '';
}

export class AcademicYear {
  id: string;
  name: string;
  isActive: boolean = true;
  description: string = '';
}
export class Classes {
  id: string;
  name: string;
  classSystemTypeId: string;
  classSystemName: string;
  IsActive: boolean;
  Description: string;
}
export class Section {
  Id: string;
  Name: string;
  IsActive: boolean;
  Description: string;
  ClassesId: string;
}
export class Student {

  id: string;
  UserName: string;
  FullName: string;
  ClassID: string;
  ClassName: string;
  SectionID: string;
  SectionName: string;
  RollNo: string;
  EnrollmentDate: Date;
  SMSContactNumber2: string;
  SMSContactNumber: string;
  FatherGuardianName: string;
  RoleName: string;
  selected : boolean;
}
export class Subjects {
  Id: string;
  Name: string;
  IsActive: boolean;
  Description: string;
  ClassesId: string;
  id: any;
  isActive: any;
  checked: boolean = false;

}
export class ClassesWithSubjectRequest {

  ClassId: string = "";
  Subjects: Subjects[] = [];
  IsActive: boolean;
  checked: boolean = false;

}
export class GetSubjectsByClassIds {
  ClassId: string = "";
}

export class LookupConfiguration {
  id: string;
  name: string;
  isActive: boolean = false;
  description: string;
}
export class Religions {
  id: string;
  name: string;
  religionTypeId: string;
  religionSystemName: string;
  isActive: boolean = false;
  description: string;
}
export class ReligionSystems {
  id: string;
  name: String;
  isActive: boolean = true;
  description: string;
}
export class GetSubjectsByClassId {
  pageNumber: number;
  pageSize: number;
  orderBy: string[] | null;
  classID: string;
  subjectID: string;
  subjectName: string;
}

export class Settings {
  id: any
  schoolName: string;
  aadharCardCertificateTemplate: string;
  academicYear: string;
  applyFeePaymentChequeBounceFine: boolean;
  applyLateFeePaymentFine: boolean;
  attendanceCheckTimeKey: string;
  attendancePeriodicCheckDurationInsec: string;
  averageExamTypesInExamSystem: boolean;
  biometricAbsentSMS: boolean;
  biometricAttendanceWRTimeStamp: boolean;
  biometricCheckOutSMS: boolean;
  biometricEmergencyContactNumber: string;
  biometricPresentSMS: boolean;
  biometricSMSContactNumber: string;
  bonafideCertificateTemplate: string;
  bookExtendMessageTemplate: string;
  bookIssueMessage: string;
  bookReturnMessage: string;
  characterCertificateTemplate: string;
  chequeBounceMessage: string;
  createdDate: string;
  currencySymbol: string;
  currentAcademicYearId: string;
  dOBCertificate: string;
  dashboardType: number;
  digitalAuthorizedSignatureImageUrl: string;
  disciplinewiseFeeReceiptConfig: boolean;
  enableAdmissionClearanceFeePaymentCheck: boolean;
  enableDiarySMSByStaff: boolean;
  enableExamMarksSMSByStaff: boolean;
  enableReadmissionFee: boolean;
  enableSiblingsModePayment: boolean;
  enableStaffIDCardTemplate: boolean;
  enableStudentAttendanceSMSByStaff: boolean;
  enableStudentIDCardTemplate: boolean;
  enableToleranceCheck: boolean;
  eventReminderMessage: string;
  feeCardTemplate: string;
  feeCertificateTemplate: string;
  feePaidMessage: string;
  feePaymentCertificateTemplate: string;
  feePaymentChequeBounceFine: string;
  feeReminderMessage: string;
  fontSizeInPrint: string;
  grantAllPeriodicAttendanceByStaff: boolean;
  hallTicketTemplate: string;
  hasMobileApp: boolean;
  holidayReminderMessage: string;
  includeCheckinSMSInCheckoutInterval: boolean;
  isSaturdayWorkDay: boolean;
  isSchoolApplication: boolean;
  isSundayWorkDay: boolean;
  language: string;
  lastModifiedDate: string;
  lateFeePaymentFine: number;
  logoImageUrl: string;
  managementSystemType: string;
  maxNoOfBooksToIssue: number;
  maxNoOfDueDays: number;
  onlineExamUrl: string;
  parentCardTemplate: string;
  periodicAttendanceGracePeriod: number;
  periodicSMSCheckinTemplate: string;
  periodicSMSCheckoutTemplate: string;
  printHeaderTemplate: string;
  printReceiptonFeePayment: boolean;
  printReceiptsSideBySide: boolean;
  progressReportTemplate: string;
  progressReportTemplateId: string;
  readmissionAcademicYearId: string;
  readmissionFeeTemplateId: string;
  readyToPromoteAcademicYearWise: boolean;
  receiptTemplate: string;
  receiptTemplateId: string;
  recordStudentBiometricPickup: boolean;
  refDate: string;
  sMSAdmissionProgress: boolean;
  sMSAdmitted: boolean;
  sMSAlternateNumber: boolean;
  sMSBookIssueMessage: boolean;
  sMSBookReturnMessage: boolean;
  sMSChequeBounceMessage: boolean;
  sMSFeePaidMesssage: boolean;
  sMSStaffBiometricAttendance: boolean;
  sMSStaffBirthdayMessage: boolean;
  sMSStudentBirthdayMessage: boolean;
  schoolAddress: string;
  schoolLogoCenteredInPrintHeader: boolean;
  schoolPolicies: string;
  sendPeriodicCheckOutSMS: boolean;
  sendPeriodicCheckinSMS: boolean;
  sendStaffBiometricAttendanceToStaff: boolean;
  showAttendanceToStudents: boolean;
  showBooksTakenToStudents: boolean;
  showBusBellToStudents: boolean;
  showDiaryToStudents: boolean;
  showEventsToStudents: boolean;
  showExamMarksToStudents: boolean;
  showExamsToStudents: boolean;
  showFeesDetailsToStudents: boolean;
  showForumToStudents: boolean;
  showHolidaysToStudents: boolean;
  showHostelDetailsToStudents: boolean;
  showOnlyCurrentPaidTermsInReceipt: boolean;
  showTimeTableToStudents: boolean;
  showTransportDetailsToStudents: boolean;
  siteName: string;
  staffAbsentMessage: string;
  staffBirthdayCardTemplate: string;
  staffBirthdayMessage: string;
  staffCredMessage: string;
  staffExperienceCertificateTemplate: string;
  staffIDCardTemplate: string;
  staffPresentMessage: string;
  staffRelievingCertificateForEvaluationTemplate: string;
  staffRelievingOrderForPracticalExamTemplate: string;
  studentAbsentMessage: string;
  studentAdmissionCertificateTemplate: string;
  studentAdmissionInProgressMessage: string;
  studentAdmittedMessage: string;
  studentBiometricCheckoutMessage: string;
  studentBirthdayCardTemplate: string;
  studentBirthdayMessage: string;
  studentCasteCertificateTemplate: string;
  studentCredMessage: string;
  studentIDCardTemplate: string;
  studentPresentMessage: string;
  studentPrintProfileTemplate: string;
  studentResultDelayTemplate: string;
  tCPrefix: string;
  tolerancePercentage: number;
  transferCertificateTemplate: string;
  trialPeriod: string;
  unlockToEveryOne: boolean;
  vORKUrl: string;
  watermarkImageUrl: string;
  hidePaidTerms: boolean;
  showPreviousTermBalance: boolean;
  showPreviousTermBalanceTest: boolean;
}

export class AdmissionClearenceFeeDetail {
  id: string;
  academicYearId: string;
  feeTerm: string;
  feeType: string;
  feeTemplete: string;
}

export class AddBiomettic {
  Sections: any
}
export class Caste {
  id: string;
  name: string;
  isActive: boolean = false;
  casteSystemTypeId: string = null;
  description: string = null;
  casteSystemTypeName: string = null;
}

export class Castesystem {
  id: string;
  name: string;
  isActive: boolean = false;
  description: string = null;
}

export class ConfigurationDto {
  id: string;
  name: string;
  isActive: boolean = false;
  description: string;
}
export class DepartmentDto {
  id: string;
  name: string;
  isActive: boolean = false;
  description: string;
}
export class PeriodDto {
  id: string;
  name: string;
  isActive: boolean = false;
  description: string;
  startTime: Date;
  endTime: Date;
}
export class Shift {
  id: string;
  name: string;
  attendanceCheckStartTime: string;
  attendancePeriodicCheckDurationInsec: string;
  enableCheckOut: boolean;
  attendanceCheckOutTime: string;
}
export class BioMetricSection {
  id: string;
  sectionId: string;
  date: string
  isActive: boolean = false;
}
export class SpecialWorkDays {
  id: string;
  date: string;
  classesId: string;
  additionalInfo: string;
  checkInStartTime: string;
  checkInEndTime: string;
  checkOutStartTime: string;
  checkOutEndTime: string;
}

export class SpecialWorkDaysMultiple {
  classes: any;
  id: string;
  date: string;
  classesId: string;
  additionalInfo: string;
  checkInStartTime: string;
  checkInEndTime: string;
  checkOutStartTime: string;
  checkOutEndTime: string;
}

export class SMSTemplateDto {
  id: string;
  name: string;
  isActive: boolean = false;
  description: string;
  message: string;
}
export class AlertDto {
  id: string;
  name: string;
  isActive: boolean = false;
  message: string;
}
export class BiometricDeviceDto {
  id: string;
  serialNumber: string;
  isActive: boolean = true;
  ipAddress: string;
  description: string;
}
export class AddSettingModel {
  CreateConfigurationSettings: Settings;
  id: any
}
export class FeeTermDto {
  id: string;
  name: string;
  startDate: string;
  dueDate: string;
  endDate: string;
  sortOrder: number;

}
export class FeeTypes {
  id: string;
  name: string;
  isActive: boolean = false;
  description: string;
}
export class FeeTypesDto {
  id: string;
  name: string;
  isActive: boolean = false;
  description: string;
}
export class FeeTemplateDto {
  id: string;
  name: string;
  isActive: boolean = false;
  class: string;
  description: string;
}
export class FeeDto {
  id: string;
  feetype: string;
  feeterm: string;
  amount: number;
}
export class RolesDto {
  id: string;
  name: string;
  isActive: boolean = false;
  description: string;

}
export class PermissionDto {
  id: string;
  name: string;
  description: string | null;
  permissions: string[];
  module: string;
}
export class PermRequestDto {
  roleId: string;
  permissions: string[];
}
export class UserDto {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  surname: string;
  address: string;
  roleName: string;
  role: string;
}
export class admission {
  id: string;
  firstName: string;
  userName: string;
  classID: string;
  sectionID: string;
  ContactNo: string;
  dateOfBirth: Date;
  gender: string;
  fatherGuardianName: string;
}
export class AdmissionDto {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  userName: string;
  password: string;
  confirmPassword: string;
  phoneNumber: string;
  surname: string;
  address: string;
  classID: string;
  className: string;
  sectionID: string;
  sectionName: string;
  rollNo: string;
  admissionID: 0;
  gender: string;
  dateOfBirth: Date;
  enrollmentDate: Date;
  enrolledClassId: string;
  enrolledinAcademicYearID: string;
  academicYearName: string;
  mediumID: string;
  religionID: string;
  casteID: string;
  casteSystemType: string;
  smsContactNumber2: string;
  smsContactNumber: string;
  otherContactNo: string;
  nationality: string;
  passport: string;
  aadharNo: string;
  languagesKnown: string;
  permanenetAddress: string;
  localAddress: string;
  healthAllergies: string;
  recentMedicalIssues: string;
  relevantMedicalDocumentation: string;
  bloodGroupID: string;
  appliedforadmissioninAcademicYearID: string;
  motherTongueID: string;
  ageasonRefDate: 0;
  emailId: string;
  bioMetricID: string;
  biometricShiftID: string;
  height: string;
  additionalInformation: string;
  registrationNo: string;
  ledgerNo: string;
  subcaste: string;
  eoStudentId: 0;
  stateID: string;
  admissionCategoryID: string;
  applicationFormNo: string;
  enrollmentNo: string;
  boardRollNo: string;
  isNCCCadet: boolean = true;
  nccCadetDetails: string;
  extraCurricularActivities: string;
  fatherGuardianName: string;
  motherName: string;
  fatherQualification: string;
  motherQualification: string;
  fatherOccupationID: string;
  motherOccupationID: string;
  distanceofAddressfromSchoolinKMS: string;
  needSchoolTransport: boolean = true;
  prevousSchoolName: string;
  previousClassID: string;
  previousMedium: string;
  previousBoardUniversity: string;
  previousYearofPassing: string;
  previousPercentageOfMarks: string;
  previousPlace: string;
  previousReference: string;
  reasonToLeavePreviousSchool: string;
  classofLeavingID: string;
  sectionofLeavingID: string;
  dateOfLeaving: Date;
  reasonForLeaving: string;
  exitAdditionalInformation: string;
  academicYearofLeavingID: string;
  isTCIssued: boolean = true;
  autoGeneratedTCNumber: 0;
  tcNumber: 0;
  tcIssuedDate: Date;
  isFailedInLastStudiedClass: boolean = true;
  lastTakenAnnualExamResult: string;
  isQualifiedToHigherClass: boolean = true;
  qualifiedToHigherClassID: string;
  tcApplicationDate: Date;
  conduct: string;
  remarks: string;

}

export class Modules {
  id: string;
  name: string;
  IsActive: boolean = false;
  Description: string = '';
  submodules: SubModules[] = [];
}
export class SubModules {
  Id: string;
  name: string;
  IsActive: boolean = false;
  description: string = '';
}

export class staff {
  id: string;
  userName: string;
  isActive: boolean;
  password: string;
  confirmPassword: string;
  imageUrl: string | null;
  name: string;
  middleName: string;
  surname: string | null;
  staffId: string | null;
  gender: string | null;
  dateOfBirth: string | null;
  enrollmentDate: string | null;
  sMSContactNumber: string | null;
  otherContactNo: string | null;
  email: string;
  address: string | null;
  healthAllergies: string | null;
  additionalInformation: string | null;
  enrolledClassId: string | null;
  enrolledSectionId: string | null;
  bioMetricID: string | null;
  biometricShiftID: string | null;
  role: string | null;
  staffType: string;
  staffDepartmentID: String | null;
  staffDesignationID: String | null;
  staffJoiningDate: string | null;
  staffExitDate: string | null;
  staffServiceType: string;
  staffPeriodofContract: string;
  staffSalary: number | null;
  staffQualification: string;
  staffWorkExperience: string;
  selected : boolean;

}
export class workingdaystemplete{
  id: string;
  Name: string;
  IsActive: boolean;
  description: string = '';
}
