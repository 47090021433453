<nav class="navbar navbar-expand-lg">
    <div class="container-fluid  p-10">
      
        <img src="/assets/images/logo.png"  style="border-radius: 50%;" height="40">
      
      <button class="navbar-toggler text-white bg-light" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon text-white"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-link text-white active" routerLinkActive="active" routerLink="/dashboard" role="button" aria-expanded="false">
            Dashboard
          </li>
          <!-- Dynamic Modules Loading -->
          <li class="nav-item dropdown" *ngFor="let moduleitem of modules">
            <!-- Check if the user has permission for this module -->
            <ng-container *ngIf="moduleitem.submodules && moduleitem.submodules.length > 0">
              <a class="nav-link dropdown-toggle text-white" routerLinkActive="active" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{moduleitem.name | titlecase}}
              </a>
              <!-- Sub Modules List -->
              <ul class="dropdown-menu dropdown-menu-end" *ngIf="moduleitem.submodules && moduleitem.name !== 'dashboard'">
                <li *ngFor="let submodule of moduleitem.submodules">
                  <!-- Check if the user has permission for this submodule -->
                  <ng-container  *ngIf="submodule.description !== undefined; else skipLogging">
                    <a class="dropdown-item" routerLinkActive="active" [routerLink]="['/' + moduleitem.description, submodule.description]">
                      {{submodule.name | titlecase}}
                    </a>
                  </ng-container>
                  <ng-template #skipLogging></ng-template>
                </li>
              </ul>
            </ng-container>
          </li>
        </ul>
        
        <form class="form-inline mx-2 my-lg-1">
          <button class="logout-btn btn my-2 my-sm-0" type="submit" (click)="logout()">LOGOUT&nbsp;
            <i class='fa-solid fa-circle-arrow-right logout-icon'></i>
          </button>
        </form>
      </div>
    </div>
  </nav>
  <div class="container-fluid sub-nav ng-scope ">
    <div class="col-lg-12 sub-nav-des-view" style="display: flex;">

      <div class="col-lg-6 text-right">
        <h3 class="ng-dash">{{currentRouteName | titlecase}}</h3>
      </div>
    <div class="col-lg-6 text-right">
    <h3 class="ng-dash">
      
    
      <a class="cursor-pointer"  routerLink="/users/profile-setting">
          <i class="fa-solid fa-user"></i>
           Profile
      </a>
      &nbsp;
      <a class="cursor-pointer"  routerLink="/users/change-password">
          <i class="fa-solid fa-pen"></i>
           Change Password
      </a>
      &nbsp;
      <a class="cursor-pointer" >
          <i class="fa-solid fa-mobile-button"></i>
           0
      </a>
      &nbsp;
        <select class="custom-select" (change)="onDropdownChange($event)">
          <option *ngFor="let item of AcademicYears" value="{{item.id}}">{{item.name}}</option>
        </select>
        
    </h3>
    </div>
  </div>
 </div>
 
