import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base.service';
declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class StudentService extends BaseService {

  constructor(private http:HttpClient) {
    super();
  }
  getstudent(data:any){
    return this.http.post<any>(this.httpSource+"student/getfilteredstudentlist?role=student",data)
  }
  getcurrentstudent(data:any){
    return this.http.post<any>(this.httpSource+"student/gecurrentstudentlist?role=student",data)
  }
  addStudent(data:any){
    return this.http.post<[any]>(this.httpSource+"student/createquickadmission",data);
  } 

  addStudentNew(data:any){
    return this.http.post<[any]>(this.httpSource+"student",data);
  } 

  deleteStudent(id:any){
    return this.http.delete<[any]>(this.httpSource+"users/" + id,id);
  }

  getquickstudents(){
    return this.http.get<any>(this.httpSource+"student?role=student")
  }

  getstudentbyid(id:any){
    return this.http.get<[any]>(this.httpSource+"student/" + id,id);
  }

  admitstudent(data:any[]){
    return this.http.post<any[]>(this.httpSource+"student/admitstudents",data);
  }

  removestudent(data:any[]){
    return this.http.post<any[]>(this.httpSource+"student/removeadmitedstudents",data);
  }

  PassOutStudent(UserId:any){
    return this.http.post<any[]>(this.httpSource+"student/updatepassedoutstatus?UserId=" + UserId,UserId)
  }

  DropOutStudent(UserId:any){
    return this.http.post<any[]>(this.httpSource+"student/updatedropoutstatus?UserId=" + UserId,UserId)
  }

  getDropOutStudent(data:any){
    return this.http.post<any>(this.httpSource+"student/getdropoutstudentslist?role=student",data)
  }
  getPromotedStudent(data:any){
    return this.http.post<any>(this.httpSource+"student/getpormotedstudentslist?role=student",data)
  }

  getPassoutStudetn(data:any){
    return this.http.post<any>(this.httpSource+"student/getpassoutstudentslist?role=student",data)
  }

  getReadyToPromoteStudentList(data:any){
    return this.http.post<any>(this.httpSource+"student/getreadytopormotedstudentslist?role=student",data)
  }
  promoteStudent(data:any){
    return this.http.post<any[]>(this.httpSource+"student/updatepromotedstatus" , data)
  }
  
}
