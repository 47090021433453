<mat-sidenav-container class='sidenav-container'>
  <mat-sidenav #sidenav class="sidenav" disableClose="false" mode="push">
    <mat-nav-list style="padding-top: 0;">
      <mat-card style="margin-bottom: 0px!important;">
        <mat-card-header style="padding:20px!important">
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title style="margin-bottom: 10px;font-size:medium">{{fullName}}</mat-card-title>
          <mat-card-subtitle style="margin-bottom: 5px;font-size:small">{{email}}</mat-card-subtitle>
        </mat-card-header>
      </mat-card>
      <div style="padding: 10px;">
        <a mat-list-item routerLink="dashboard/" (click)="sidenav.close()">
          <mat-icon style="padding-right: 10px;">trending_up</mat-icon> Dashboard
        </a>
        <div class="nav-subheading">
          {{'layouts.admin.catalogSubhead'|translate}}</div>
        <a *appHasPermission='["Permissions.Brands.Search"]' mat-list-item routerLink="catalog/brands"
          (click)="sidenav.close()">
          <mat-icon style="padding-right: 10px;">inventory</mat-icon> {{'layouts.admin.brands'|translate}}
        </a>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <app-toolbar [inputSideNav]="sidenav"></app-toolbar>
  <div style="height: 2px;">
    <mat-progress-bar *ngIf="busyService.isLoading | async" mode="indeterminate" color="accent"></mat-progress-bar>
  </div>
  <div class="content">
    <div class="container">
      <mat-card *ngIf="alertIsVisible" class="module-card">
        <mat-card-content>
          <div class="row" style="align-items: center;">
          </div>
        </mat-card-content>
      </mat-card>
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-sidenav-container>
