import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "src/app/base.service";

@Injectable({
    providedIn: 'root'
  })
export class ConfigurationService extends BaseService
{
  Deleteitem() {
    throw new Error('Method not implemented.');
  }
    constructor(
        private http:HttpClient
      ) { 
        super()
      }
      UpdateAcademicYear(data:any)
      {
        return this.http.post<[any]>(this.httpSource + "v1/academicyear/" + data.Id + "/updateacademicyearbyid", data)
      }

      DeleteAcademicYear(id:any)
      {
        return this.http.post<[any]>(this.httpSource + "v1/academicyear/" + id + "/deleteacademicyearbyid", id)
        }

      AddAcademicYear(data:any){
        return this.http.post<[any]>(this.httpSource+"v1/academicyear/addacademicyear",data);
      }
      GetAcademicYear(){
        return this.http.get<[any]>(this.httpSource+"v1/academicyear/gelallacademicyears");
      }
    
      GetAcademicYearById(Id:any)
      {
        return this.http.get<[any]>(this.httpSource+"v1/academicyear/"+Id+"/getacademiyearbyid",Id);
      }
 // Religion
      UpdateReligion(data:any)
      {
        return this.http.post<[any]>(this.httpSource + "v1/religion/updatereligion/" + data.id , data)
      }
      UpdateReligionsystem(data:any)
      {
        return this.http.post<[any]>(this.httpSource + "v1/religion/updatereligionsystemtypebyid/" + data.id , data)
      }
      AddReligion(data:any){
        return this.http.post<[any]>(this.httpSource+"v1/religion/addreligion",data);
      }
      GetAllReligions(){
        return this.http.get<[any]>(this.httpSource+"v1/religion/getallrelegion");
      }
      GetAllReligionSystems(){
        return this.http.get<[any]>(this.httpSource+"v1/religion/getallrelegionsystem");
      }
      AddReligionSystems(data:any){
        return this.http.post<[any]>(this.httpSource+"v1/religion/addreligionsystemtype",data);
      }
      DeleteReligionById(id:any)
      {
        return this.http.post<[any]>(this.httpSource + "v1/religion/deletereligion/" + id , id)
        }
       DeleteReligionsystemById(id:any)
      {
        return this.http.delete<[any]>(this.httpSource + "v1/religion/deletereligionsystemtype/" + id, id)
        }
        GetReligionById(Id:any)
      {
        return this.http.get<[any]>(this.httpSource+"v1/religion/"+Id+"/getacademiyearbyid",Id);
      }
      // Lookup Configuration
      GetLookupConfigurationLanguage(){
        return this.http.get<[any]>(this.httpSource+"v1/lookupconfiguration/gelalllookupconfigurationlanguages");
      }
      GetLookupConfigurationBloodGroup(){
        return this.http.get<[any]>(this.httpSource+"v1/lookupconfiguration/gelalllookupconfigurationbloodgroup");
      }
      GetLookupConfigurationOccupation(){
        return this.http.get<[any]>(this.httpSource+"v1/lookupconfiguration/gelalllookupconfigurationoccupation");
      }

      AddLookupConfigurationLanguage(data:any){
        return this.http.post<[any]>(this.httpSource+"v1/lookupconfiguration/addlookupconfigurationlanguage",data);
      }
      AddLookupConfigurationBloodGroup(data:any){
        return this.http.post<[any]>(this.httpSource+"v1/lookupconfiguration/addlookupconfigurationbloodgroup",data);
      }
      AddLookupConfigurationOccupation(data:any){
        return this.http.post<[any]>(this.httpSource+"v1/lookupconfiguration/addlookupconfigurationoccupation",data);
      }

      DeleteLookupConfigurationLanguage(id:any)
      {
        return this.http.post<[any]>(this.httpSource + "v1/lookupconfiguration/deletelookupconfigurationlanguage/" + id,id)
      }
      DeleteLookupConfigurationBloodGroup(id:any)
      {
        return this.http.post<[any]>(this.httpSource + "v1/lookupconfiguration/deletelookupconfigurationbloodgroup/" + id , id)
      }
      DeleteLookupConfigurationOccupation(id:any)
      {
        return this.http.post<[any]>(this.httpSource + "v1/lookupconfiguration/deletelookupconfigurationoccupation/" + id, id)
      }

      UpdateLookupConfigurationLanguage(data:any)
      {
        return this.http.post<[any]>(this.httpSource + "v1/lookupconfiguration/updatelookupconfigurationlanguage/" + data.id, data)
      }
      UpdateLookupConfigurationBloodGroup(data:any)
      {
        return this.http.post<[any]>(this.httpSource + "v1/lookupconfiguration/updatelookupconfigurationbloodgroup/" + data.id, data)
      }
      UpdateLookupConfigurationOccupation(data:any)
      {
        return this.http.post<[any]>(this.httpSource + "v1/lookupconfiguration/updatelookupconfigurationoccupation/" + data.id, data)
      }

      // castes
      getCastes(){
        return this.http.get<[any]>(this.httpSource+"v1/caste/getallcastes");
      }
      
      getCastesystem(){
        return this.http.get<[any]>(this.httpSource+"v1/caste/getallcastesystemtypes");
      }
      addCaste(data:any){
        return this.http.post<[any]>(this.httpSource+"v1/caste/addcaste",data);
      }
      addCastesystem(data:any){
        return this.http.post<[any]>(this.httpSource+"v1/caste/addcastesystemtype",data);
      }
      deleteCaste(id:any)
      {
        return this.http.delete<[any]>(this.httpSource + "v1/caste/deletecaste/" + id,id)
      }
      deleteCastesystem(id:any)
      {
        return this.http.delete<[any]>(this.httpSource + "v1/caste/deletecastesystemtype/" + id,id)
      }
      updateCaste(data:any)
      {
        return this.http.put<[any]>(this.httpSource + "v1/caste/updatecaste/" + data.id, data)
      }
      updateCastesystem(data:any)
      {
        return this.http.put<[any]>(this.httpSource + "v1/caste/updatecastesystemtypebyid/" + data.id, data)
      }

     


}
