        <!-- <nav class="navbar navbar-expand-lg">
          <div class="container-fluid  p-10">
            <a class="navbar-brand text-white" href="#">
              <img src="/assets/images/logo.png" alt="Logo" style="border-radius: 50%;" height="40">
            </a>
            <button class="navbar-toggler text-white bg-light" type="button" data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
              aria-label="Toggle navigation">
              <span class="navbar-toggler-icon text-white"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0 ">
                <a class="nav-link  text-white" href="../index.html" role="button" aria-expanded="false">
                  Dashboard
                </a>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Configurations
                  </a>
                  <ul class="dropdown-menu dropdown-menu-end">
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="Academic">Academic Years</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="classes">Classes</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="sections">Sections</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="subject">Subjects</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="religions">Religions</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="periods">Periods</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="breaktypes">Break Types</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="workingdaystemplete">WorkingDays Templates</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="classes">TimeTable Templates</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="timetable">Timetable</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="createtimetable">CreateTimetable</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="castes">Castes</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="mediums">Mediums</a></li>
                    <li><a class="dropdown-item"routerLinkActive="active" routerLink="departments">Departments</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="designation">Designations</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="smstemplete">SMS Templates</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="setting">Settings</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="alerts">Alerts</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="hobbyclub">Hobby Club</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="biometricdevices">Biometric Device</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="admissioncategory">Admission Category</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="pagesconfiguration">Page Configuration</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="lookupsconfiguration">Lookups Configuration</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Operations
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item"routerLinkActive="active" routerLink="studentattendance">Student Attendance</a></li>
                    <li><a class="dropdown-item"routerLinkActive="active" routerLink="periodicattendance">Student Periodic Attendance</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="simplesms">Simple SMS</a></li>
                    <li><a class="dropdown-item"routerLinkActive="active" routerLink="voicesms">Voice SMS</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="advancesms">Advanced SMS</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="holidays">Holidays</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="events">Events</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="smscontactgroup">SMS Contact Groups</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="dairy">Diary</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="dailyupdate">Daily Update</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="studentbirthdaylist">Student Birthday List</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="staffbirthdaylist">Staff Birthday List</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="staffattendance">Staff Attendance</a></li>
                    <li><a class="dropdown-item" routerLinkActive="active" routerLink="#">PayRoll Management</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Academic Planner
                  </a>
                  <ul class="dropdown-menu ">
                    <li><a class="dropdown-item" href="./AcademicPlanner/CreateSyllabus.html">Create Syllabus</a></li>
                    <li><a class="dropdown-item" href="./AcademicPlanner/ScheduleSyllabus.html">Schedule Syllabus</a></li>
                    <li><a class="dropdown-item" href="./AcademicPlanner/TrackSyllabus.html">Track Syllabus</a></li>
                    <li><a class="dropdown-item" href="./AcademicPlanner/ViewSyllabus.html">View Syllabus</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Users
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="./User/AdmissionManagement.html">Admission Management</a></li>
                    <li><a class="dropdown-item" href="./User/QuickAdmissionManagement.html">Quick Admission Management</a></li>
                    <li><a class="dropdown-item" href="./User/ManageCurrentStudents.html">Manage Current Students</a></li>
                    <li><a class="dropdown-item" href="./User/ManagePassedoutStudents.html">Manage Passedout Students</a></li>
                    <li><a class="dropdown-item" href="./User/PromoteStudents.html">Promote Students</a></li>
                    <li><a class="dropdown-item" href="./User/ManageDropOutStudents.html">Manage DropOut Students</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Exams
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="./Exams/ExamCommonTypes.html">Exam Common Types</a></li>
                    <li><a class="dropdown-item" href="./Exams/ExamTypes.html">Exam Types</a></li>
                    <li><a class="dropdown-item" href="./Exams/GradeSystems.html">Grade Systems</a></li>
                    <li><a class="dropdown-item" href="./Exams/SubjectAreas.html">Subject Areas</a></li>
                    <li><a class="dropdown-item" href="./Exams/ExamSystems.html">Exam Systems</a></li>
                    <li><a class="dropdown-item" href="./Exams/Exams.html">Exams</a></li>
                    <li><a class="dropdown-item" href="./Exams/ExamMarks.html">Exam Marks</a></li>
                    <li><a class="dropdown-item" href="./Exams/ExamSubjectAreaMarks.html">Exam Subject Area Marks</a></li>
                    <li><a class="dropdown-item" href="./Exams/Co-ScholasticAreas.html">Co-Scholastic Areas</a></li>
                    <li><a class="dropdown-item" href="./Exams/Co-ScholasticGradeSystems.html">Co-Scholastic Grade Systems</a></li>
                    <li><a class="dropdown-item" href="./Exams/Co-ScholasticMarks.html">Co-Scholastic Marks</a></li>
                    <li><a class="dropdown-item" href="./Exams/HallTickets.html">Hall Tickets</a></li>
                    <li><a class="dropdown-item" href="./Exams/ProgressReport.html">Progress Report</a></li>
                    <li><a class="dropdown-item" href="./Exams/PromoteExamStructure.html">Promote Exam Structure</a></li>
                    <li><a class="dropdown-item" href="./Exams/OnlineExam.html">Online Exam</a></li>
                    <li><a class="dropdown-item" href="./Exams/ExamTermRankPosition.html">ExamTeam Pank Position</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Fees
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="./Fees/FeeTerms.html">Fee Terms</a></li>
                    <li><a class="dropdown-item" href="./Fees/FeeTypes.html">Fee Types</a></li>
                    <li><a class="dropdown-item" href="./Fees/FeeTemplates.html">Fee Templates</a></li>
                    <li><a class="dropdown-item" href="./Fees/Fees.html">Fees</a></li>
                    <li><a class="dropdown-item" href="./Fees/FeeStructure.html">Fee Structure</a></li>
                    <li><a class="dropdown-item" href="./Fees/SendFeeReminders.html">Send Fee Reminders</a></li>
                    <li><a class="dropdown-item" href="./Fees/PromoteFees.html">Promote Fees</a></li>
                    <li><a class="dropdown-item" href="./Fees/FeeDemandNotices.html">Fee Demand Notices</a></li>
                    <li><a class="dropdown-item" href="./Fees/StudentFeeCards.html">Student Fee Cards</a></li>
                    <li><a class="dropdown-item" href="./Fees/FeeSettings.html">Fee Settings</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Hostels
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="./Hostels/Hostels.html">Hostels</a></li>
                    <li><a class="dropdown-item" href="./Hostels/Room.html">Rooms</a></li>
                    <li><a class="dropdown-item" href="./Hostels/Students.html">Students</a></li>
                    <li><a class="dropdown-item" href="./Hostels/StudentOutpasses.html">Student Outpasses</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Library
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="./Library/BookCategory.html">Book Category</a></li>
                    <li><a class="dropdown-item" href="./Library/BookStore.html">Book Store</a></li>
                    <li><a class="dropdown-item" href="./Library/ManageBook.html">Manage Book</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Transport
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="./Transport/Dashboard.html">Dashboard</a></li>
                    <li><a class="dropdown-item" href="./Transport/Vehicles.html">Vehicles</a></li>
                    <li><a class="dropdown-item" href="./Transport/TransportFeeTypes.html">Transport FeeTypes</a></li>
                    <li><a class="dropdown-item" href="./Transport/TransportFeeTemplates.html">Transport FeeTemplates</a></li>
                    <li><a class="dropdown-item" href="./Transport/BusStops.html">Bus Stops</a></li>
                    <li><a class="dropdown-item" href="./Transport/Routes.html">Routes</a></li>
                    <li><a class="dropdown-item" href="./Transport/RouteStudents.html">Route Students</a></li>
                    <li><a class="dropdown-item" href="./Transport/VehicleSchedules.html">Vehicle Schedules</a></li>
                    <li><a class="dropdown-item" href="./Transport/Holidays.html">Holidays</a></li>
                    <li><a class="dropdown-item" href="./Transport/Settings.html">Settings</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Reports
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#">Balance Dashboard</a></li>
                    <li><a class="dropdown-item" href="#">Fee Collected Report</a></li>
                    <li><a class="dropdown-item" href="#">Detailed Fee Collected Report Termwise</a></li>
                    <li><a class="dropdown-item" href="#">Fee Collected Report by ReceiptNo</a></li>
                    <li><a class="dropdown-item" href="#">Fee Balance Report</a></li>
                    <li><a class="dropdown-item" href="#">Fee Summary Report Termwise</a></li>
                    <li><a class="dropdown-item" href="#">Fee Adjustment Report</a></li>
                    <li><a class="dropdown-item" href="#">Deleted Fee Receipts Report</a></li>
                    <li><a class="dropdown-item" href="#">Bounced Fee Receipts Report</a></li>
                    <li><a class="dropdown-item" href="#">Monthly Attendance Summary</a></li>
                    <li><a class="dropdown-item" href="#">Daywise Attendance Summary</a></li>
                    <li><a class="dropdown-item" href="#">View Absented Students</a></li>
                    <li><a class="dropdown-item" href="#">Exam Marks</a></li>
                    <li><a class="dropdown-item" href="#">View Students By Hobby Club</a></li>
                    <li><a class="dropdown-item" href="#">Dise Report</a></li>
                    <li><a class="dropdown-item" href="#">Dise Report By Religion</a></li>
                    <li><a class="dropdown-item" href="#">Dise Report By Caste System Type</a></li>
                    <li><a class="dropdown-item" href="#">New Students Admitted In AcademicYear</a></li>
                    <li><a class="dropdown-item" href="#">Student Fee Consolidated Report</a></li>
                    <li><a class="dropdown-item" href="#">Route Students Report</a></li>
                    <li><a class="dropdown-item" href="#">Books Circulation Report</a></li>
                    <li><a class="dropdown-item" href="#">Staff Attendance Report</a></li>
                    <li><a class="dropdown-item" href="#">Transport Fee Summary Report</a></li>
                    <li><a class="dropdown-item" href="#">Student Monthwise Attendance Report</a></li>
                    <li><a class="dropdown-item" href="#">Staff Monthwise Attendance Report</a></li>
                    <li><a class="dropdown-item" href="#">Overall Fee Summary Report</a></li>
                    <li><a class="dropdown-item" href="#">Overall Marks Percentage Report</a></li>
                    <li><a class="dropdown-item" href="#">SMS Summary Report</a></li>
                    <li><a class="dropdown-item" href="#">SMS Detail Report</a></li>
                    <li><a class="dropdown-item" href="#">Exam Termwise Attendance Report</a></li>
                    <li><a class="dropdown-item" href="#">Cheque Collection Report</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Security
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="./Security/Roles.html">Roles</a></li>
                    <li><a class="dropdown-item" href="./Security/Administrators.html">Administrators</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Wallet
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="./Wallet/ExpenseCategories.html">Expense Categories</a></li>
                    <li><a class="dropdown-item" href="./Wallet/IncomeCategories.html">Income Categories</a></li>
                    <li><a class="dropdown-item" href="./Wallet/Expenses.html">Expenses</a></li>
                    <li><a class="dropdown-item" href="./Wallet/Incomes.html">Incomes</a></li>
                    <li><a class="dropdown-item" href="./Wallet/ExpensesReport.html">Expenses Report</a></li>
                    <li><a class="dropdown-item" href="./Wallet/IncomeReport.html">Income Report</a></li>
                    <li><a class="dropdown-item" href="./Wallet/BalanceReport.html">Balance Report</a></li>
                    <li><a class="dropdown-item" href="./Wallet/DetailedBalanceReport.html">Detailed Balance Report</a></li>
                    <li><a class="dropdown-item" href="./Wallet/DaywiseFeeIncomeSourceOfStore.html">Daywise Fee Income Source Of Store</a></li>
                    <li><a class="dropdown-item" href="./Wallet/PettyCashDashboard.html">Petty Cash Dashboard</a></li>
                  </ul>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link dropdown-toggle text-white" href="#" role="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Forum
                  </a>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="./Forum/MessageType.html">Message Type</a></li>
                    <li><a class="dropdown-item" href="./Forum/MyWall.html">My Wall</a></li>
                    <li><a class="dropdown-item" href="./Forum/Settings.html">Settings</a></li>
                  </ul>
                </li>
      
              </ul>
              <form class="form-inline mx-2 my-lg-1">
                <button class="logout-btn btn my-2 my-sm-0" type="submit">LOGOUT&nbsp;
                  <i class='fa-solid fa-circle-arrow-right logout-icon'></i>
                </button>
              </form>
            </div>
          </div>
        </nav> -->


        
  <app-navbar-layout></app-navbar-layout>

<router-outlet></router-outlet>