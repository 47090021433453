<div class="content">
  <div class="container">
    <div class="home-center-screen">
      <div class="row">
        <div class="col-md-12 col-xl-12">
          <div class="c-card" style="text-align: center;">
            <div>
              <h1 style="font-size: xx-large;">404</h1>
              <h2 translate>components.notFound.header</h2>
              <p translate>components.notFound.text</p>
              <button mat-raised-button color="primary" routerLink="/">{{'components.notFound.button'|translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>