<!DOCTYPE html>
<html lang="en">
<body>    
      
  <div class="container-fluid sub-nav ng-scope">
    <div class="col-lg-12" style="display: flex;">
    <div class="col-lg-6 text-left">
      <span id="tilebtn">List View</span>
      <span id="gridebtn"> Gride View</span>
    </div>
    <div class="col-lg-6 text-right">
    <h3 class="ng-dash">
      
      <a class="cursor-pointer">
        Dashboard
    </a>
    &nbsp;
      <a class="cursor-pointer"  href="./profile.html">
          <i class="fa-solid fa-user"></i>
           Profile
      </a>
      &nbsp;
      <a class="cursor-pointer"  href="./changepassword.html">
          <i class="fa-solid fa-pen"></i>
           Change Password
      </a>
      &nbsp;
      <a class="cursor-pointer" >
          <i class="fa-solid fa-mobile-button"></i>
           35425
      </a>
      &nbsp;
        <select class="custom-select" >
          <option selected>ACT:2022-23</option>
          <option value="1">ACT:2018-19</option>
          <option value="2">ACT:2019-20</option>
          <option value="3">ACT:2020-21</option>
          <option value="4">ACT:2021-22</option>
          <option value="5">ACT:2022-23</option>
          <option value="6">ACT:2022-24</option>
        </select>
        
    </h3>
    </div>
  </div>
 </div>

    <div class="main TileView" id="tile-content">
       
        <ul class="cards">
          <li class="cards_item">
            <div class="card card_content1">
                <h2 class="card_title">Overall Fee Collected</h2>
                <div class="card-body text-center">
                  <img style="width: 50%;" src="/assets/images/OverallFeeCollected.png" alt="CoolBrand">
                </div>
            </div>
          </li>
          <li class="cards_item">
            <div class="card card_content2">
                <h2 class="card_title">Current Term Balance</h2>
                <div class="card-body text-center">
                  <img style="width: 50%;" src="/assets/images/TermwiseBalance.png" alt="CoolBrand">
                </div>
            </div>
          </li>
          <li class="cards_item">
            <div class="card card_content3">
                <h2 class="card_title">Termwise Balance</h2>
                <div class="card-body text-center">
                  <img style="width: 50%;" src="/assets/images/TermwiseBalance.png" alt="CoolBrand">
                </div>
            </div>
          </li>
          <li class="cards_item">
            <div class="card card_content4">
                <h2 class="card_title">Top Students & balance</h2>
                <div class="card-body text-center">
                  <img style="width: 50%;" src="/assets/images/TopStudents&balance.png" alt="CoolBrand">
                </div>
            </div>
          </li>
          <li class="cards_item">
            <div class="card card_content5">
                <h2 class="card_title">Sectionwise Attendance</h2>
                <div class="card-body text-center">
                  <img style="width: 50%;" src="/assets/images/SectionwiseAttendance.png" alt="CoolBrand">
                </div>
            </div>
          </li>
          <li class="cards_item">
            <div class="card card_content6">
                <h2 class="card_title">Classwise Attendance</h2>
                <div class="card-body text-center">
                  <img style="width: 50%;" src="/assets/images/ClasswiseAttendance.png" alt="CoolBrand">
                </div>
            </div>
          </li>
          <li class="cards_item">
            <div class="card card_content7">
                <h2 class="card_title">Head Count</h2>
                <div class="card-body text-center">
                  <img style="width: 50%;" src="/assets/images/HeadCount.png" alt="CoolBrand">
                </div>
            </div>
          </li>
          <li class="cards_item">
            <div class="card card_content8">
                <h2 class="card_title">Transport Summary</h2>
                <div class="card-body text-center">
                  <img style="width: 50%;" src="/assets/images/TransportSummary.png" alt="CoolBrand">
                </div>
            </div>
          </li>
          <li class="cards_item">
            <div class="card card_content9">
                <h2 class="card_title">Upcoming Exams</h2>
                <div class="card-body text-center">
                  <img style="width: 50%;" src="/assets/images/UpcomingExams.png" alt="CoolBrand">
                </div>
            </div>
          </li>
          <li class="cards_item">
            <div class="card card_content10">
                <h2 class="card_title">Upcoming Events</h2>
                <div class="card-body text-center">
                  <img style="width: 50%;" src="/assets/images/UpcomingEvents.png" alt="CoolBrand">
                </div>
            </div>
          </li>
          <li class="cards_item">
            <div class="card card_content11">
                <h2 class="card_title">Upcoming Holidays</h2>
                <div class="card-body text-center">
                  <img style="width: 50%;" src="/assets/images/UpcomingHolidays.png" alt="CoolBrand">
                </div>
            </div>
          </li>
          <li class="cards_item">
            <div class="card card_content12">
                <h2 class="card_title">Birthday Students List</h2>
                <div class="card-body text-center">
                  <img style="width: 50%;" src="/assets/images/BirthdayStudentsList.png" alt="CoolBrand">
                </div>
            </div>
          </li>
        </ul>
      </div>
      
  
      <div class="m-lg-5 container-top GrideView" style="display: none;" id="gride-content">
        <div class="col-lg-12 ">
          <div class="row tile-view">
    
          <!-- Overall Fee Collected -->
          <div class="col-lg-2 tile-view">
    
            <div class="panel-heading hide-in-print">
              <div class="row">
                <div class="col-lg-8 heading ng-binding">Overall Fee Collected</div>
                <div class="col-lg-4 right-align">
                  <span class="btn refresh-button" ng-click="RefreshWidget()">
                    <span class="glyphicon glyphicon-refresh" aria-hidden="true"><i class="fa-sharp fa-solid fa-rotate"></i></span>
                  </span>
                  <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                    <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-sharp fa-solid fa-print"></i></span>
                  </span>
                </div>
              </div>
            </div>
            <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th>No:</th>
                  <th>Time</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-column="No:">1</td>
                  <td data-column="Time"><a href="#">Today</a></td>
                  <td data-column="Amount">₹ 5,200.00</td>
                </tr>
                <tr>
                  <td data-column="No:">2</td>
                  <td data-column="Time"><a href="#">Yesterday</a></td>
                  <td data-column="Amount">₹ 34,500.00</td>
                </tr>
                <tr>
                  <td data-column="No:">3</td>
                  <td data-column="Time"><a href="#">Last 7 Days</a></td>
                  <td data-column="Amount">₹ 1,68,010.00</td>
                </tr>
                <tr>
                  <td data-column="No:">4</td>
                  <td data-column="Time"><a href="#">Current Month</a></td>
                  <td data-column="Amount">₹ 60,600.00</td>
                </tr>
                <tr>
                  <td data-column="No:">5</td>
                  <td data-column="Time"><a href="#">Last 30 Days</a></td>
                  <td data-column="Amount">₹ 6,57,520.00 </td>
                </tr>
                <tr>
                  <td data-column="No:">6</td>
                  <td data-column="Time"><a href="">Current Term</a></td>
                  <td data-column="Amount">₹ 60,600.00 </td>
                </tr>
              </tbody>
            </table>
          </div>
            <div class="panel-footer">
              <div class="row">
                <div class=" footer ng-binding "><a href="#" style="margin-right: 1%;">View More</a></div>
              </div>
            </div>
          </div>
    
          <!-- Current Term Balance Due - Classwise -->
          <div class="col-lg-5 tile-view">
    
            <div class="panel-heading hide-in-print">
              <div class="row">
                <div class="col-xs-6 heading ng-binding">Current Term Balance Due - Classwise</div>
                <div class="col-xs-6 right-align">
                  <span class="btn refresh-button" ng-click="RefreshWidget()">
                    <span class="glyphicon glyphicon-refresh" aria-hidden="true"><i class="fa-sharp fa-solid fa-rotate"></i></span>
                  </span>
                  <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                    <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-sharp fa-solid fa-print"></i></span>
                  </span>
                </div>
              </div>
            </div>
            <div class="table-content">
            <table>
              <thead>
                <tr>
                  <th>No:</th>
                  <th>Class</th>
                  <th>Fee</th>
                  <th>Adjustment</th>
                  <th>Total Fee</th>
                  <th>Paid</th>
                  <th>Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-column="No:">1</td>
                  <td data-column="Class"><a href="#">NURSERY</a></td>
                  <td data-column="Fee">₹ 30,100.00</td>
                  <td data-column="Adjustment">₹ -450.00</td>
                  <td data-column="Total Fee">₹ 29,650.00</td>
                  <td data-column="Paid">₹ 0.00</td>
                  <td data-column="Balance">₹ 29,650.00</td>
                </tr>
                <tr>
                  <td data-column="No:">2</td>
                  <td data-column="Class"><a href="#"> L.KG</a></td>
                  <td data-column="Fee">₹ 48,550.00</td>
                  <td data-column="Adjustment">₹ -1,250.00</td>
                  <td data-column="Total Fee">₹ 47,300.00</td>
                  <td data-column="Paid">₹ 1,900.00</td>
                  <td data-column="Balance">₹ 45,400.00</td>
                </tr>
                <tr>
                  <td data-column="No:">3</td>
                  <td data-column="Class"><a href="#">U.KG</a></td>
                  <td data-column="Fee">₹ 49,100.00</td>
                  <td data-column="Adjustment">₹ -90.00</td>
                  <td data-column="Total Fee">₹ 49,010.00</td>
                  <td data-column="Paid">₹ 2,500.00</td>
                  <td data-column="Balance">₹ 46,510.00</td>
                </tr>
                <tr>
                  <td data-column="No:">4</td>
                  <td data-column="Class"><a href="#">I</a></td>
                  <td data-column="Fee">₹ 67,600.00</td>
                  <td data-column="Adjustment">₹ -300.00</td>
                  <td data-column="Total Fee">₹ 67,300.00</td>
                  <td data-column="Paid">₹ 5,100.00</td>
                  <td data-column="Balance"> 62,200.00</td>
                </tr>
                <tr>
                  <td data-column="No:">5</td>
                  <td data-column="Class"><a href="#">II</a></td>
                  <td data-column="Fee">₹ 59,800.00</td>
                  <td data-column="Adjustment">₹ 500.00</td>
                  <td data-column="Total Fee">₹ 60,300.00</td>
                  <td data-column="Paid">₹ 1,900.00</td>
                  <td data-column="Balance">₹ 58,400.00</td>
                </tr>
                <tr>
                  <td data-column="No:">6</td>
                  <td data-column="Class"><a href="#">III</a></td>
                  <td data-column="Fee">₹ 81,600.00</td>
                  <td data-column="Adjustment">₹ -200.00</td>
                  <td data-column="Total Fee">₹ 81,400.00</td>
                  <td data-column="Paid">₹ 2,000.00</td>
                  <td data-column="Balance">₹ 79,400.00</td>
                </tr>
                <tr>
                  <td data-column="No:">7</td>
                  <td data-column="Class"><a href="#">IV</a></td>
                  <td data-column="Fee">₹ 72,050.00</td>
                  <td data-column="Adjustment">₹ 3,250.00</td>
                  <td data-column="Total Fee">₹ 75,300.00</td>
                  <td data-column="Paid">₹ 2,100.00</td>
                  <td data-column="Balance">₹ 73,200.00</td>
                </tr>
                <tr>
                  <td data-column="No:">8</td>
                  <td data-column="Class"><a href="#">V</a></td>
                  <td data-column="Fee">₹ 72,150.00</td>
                  <td data-column="Adjustment">₹ 1,000.00</td>
                  <td data-column="Total Fee">₹ 73,150.00</td>
                  <td data-column="Paid">₹ 2,100.00</td>
                  <td data-column="Balance">₹ 71,050.00</td>
                </tr>
                <tr>
                  <td data-column="No:">9</td>
                  <td data-column="Class"><a href="#">VI</a></td>
                  <td data-column="Fee">₹ 1,02,200.00</td>
                  <td data-column="Adjustment">₹ -900.00</td>
                  <td data-column="Total Fee">₹ 1,01,300.00</td>
                  <td data-column="Paid">₹ 0.00</td>
                  <td data-column="Balance">₹ 1,01,300.00</td>
                </tr>
                <tr>
                  <td data-column="No:">10</td>
                  <td data-column="Class"><a href="#">VII</a></td>
                  <td data-column="Fee">₹ 74,400.00</td>
                  <td data-column="Adjustment">₹ 3,100.00</td>
                  <td data-column="Total Fee">₹ 77,500.00</td>
                  <td data-column="Paid">₹ 3,400.00</td>
                  <td data-column="Balance">₹ 74,100.00</td>
                </tr>
                <tr>
                  <td data-column="No:">11</td>
                  <td data-column="Class"><a href="#">VIII</a></td>
                  <td data-column="Fee">₹ 85,650.00</td>
                  <td data-column="Adjustment">₹ 1,850.00</td>
                  <td data-column="Total Fee">₹ 87,500.00</td>
                  <td data-column="Paid">₹ 3,500.00</td>
                  <td data-column="Balance">₹ 84,000.00</td>
                </tr>
                <tr>
                  <td data-column="No:">12</td>
                  <td data-column="Class"><a href="#">IX</a></td>
                  <td data-column="Fee">₹ 77,350.00</td>
                  <td data-column="Adjustment">₹ 3,750.00</td>
                  <td data-column="Total Fee">₹ 81,100.00</td>
                  <td data-column="Paid">₹ 1,800.00</td>
                  <td data-column="Balance">₹ 79,300.00</td>
                </tr>
                <tr>
                  <td data-column="No:">13</td>
                  <td data-column="Class"><a href="#">X</a></td>
                  <td data-column="Fee">₹ 55,450.00</td>
                  <td data-column="Adjustment">₹ 750.00</td>
                  <td data-column="Total Fee">₹ 56,200.00</td>
                  <td data-column="Paid">₹ 0.00</td>
                  <td data-column="Balance">₹ 56,200.00</td>
                </tr>
              </tbody>
            </table>
          </div>
            <div class="panel-footer">
              <div class="row">
                <div class=" footer ng-binding "><a href="#" style="margin-right: 1%;">View More</a></div>
              </div>
            </div>
           </div> 
           <!-- Termwise Balance Due (for all Classes) -->
           <div class="col-lg-5 tile-view">
     
             <div class="panel-heading hide-in-print">
               <div class="row">
                 <div class="col-xs-6 heading ng-binding">Termwise Balance Due (for all Classes)</div>
                 <div class="col-xs-6 right-align">
                   <span class="btn refresh-button" ng-click="RefreshWidget()">
                     <span class="glyphicon glyphicon-refresh" aria-hidden="true"><i class="fa-sharp fa-solid fa-rotate"></i></span>
                   </span>
                   <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                     <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-sharp fa-solid fa-print"></i></span>
                   </span>
                 </div>
               </div>
             </div>
             <div class="table-content">
              <table>
                <thead>
                  <tr>
                    <th>No:</th>
                    <th>FeeTerm</th>
                    <th>Fee</th>
                    <th>Adjustment</th>
                    <th>Total Fee</th>
                    <th>Paid</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td data-column="No:">1</td>
                    <td data-column="FeeTerm"><a href="#">APRIL</a></td>
                    <td data-column="Fee">₹ 31,27,600.00</td>
                    <td data-column="Adjustment">₹ -3,82,340.00</td>
                    <td data-column="Total Fee">₹ 27,45,260.00</td>
                    <td data-column="Paid">₹ 17,28,400.00</td>
                    <td data-column="Balance">₹ 10,16,860.00</td>
                  </tr>
                  <tr>
                    <td data-column="No:">2</td>
                    <td data-column="FeeTerm"><a href="#">MAY</a></td>
                    <td data-column="Fee">₹ 8,77,300.00</td>
                    <td data-column="Adjustment">₹ 10,860.00</td>
                    <td data-column="Total Fee">₹ 8,88,160.00</td>
                    <td data-column="Paid">₹ 7,69,010.00</td>
                    <td data-column="Balance">₹ 1,19,150.00</td>
                  </tr>
                  <tr>
                    <td data-column="No:">3</td>
                    <td data-column="FeeTerm"><a href="#">JUNE</a></td>
                    <td data-column="Fee">₹ 8,77,300.00</td>
                    <td data-column="Adjustment">₹ 8,660.00</td>
                    <td data-column="Total Fee">₹ 8,85,960.00</td>
                    <td data-column="Paid">₹ 2,500.00</td>
                    <td data-column="Balance">₹ 46,510.00</td>
                  </tr>
                  <tr>
                    <td data-column="No:">4</td>
                    <td data-column="FeeTerm"><a href="#">JULY</a></td>
                    <td data-column="Fee">₹ 8,77,300.00</td>
                    <td data-column="Adjustment">₹ -300.00</td>
                    <td data-column="Total Fee">₹ 67,300.00</td>
                    <td data-column="Paid">₹ 5,100.00</td>
                    <td data-column="Balance"> 62,200.00</td>
                  </tr>
                  <tr>
                    <td data-column="No:">5</td>
                    <td data-column="FeeTerm"><a href="#">AUGUST</a></td>
                    <td data-column="Fee">₹ 8,77,300.00</td>
                    <td data-column="Adjustment">₹ 500.00</td>
                    <td data-column="Total Fee">₹ 60,300.00</td>
                    <td data-column="Paid">₹ 1,900.00</td>
                    <td data-column="Balance">₹ 58,400.00</td>
                  </tr>
                  <tr>
                    <td data-column="No:">6</td>
                    <td data-column="FeeTerm"><a href="#">SEPTEMBER</a></td>
                    <td data-column="Fee">₹ 8,77,300.00</td>
                    <td data-column="Adjustment">₹ -200.00</td>
                    <td data-column="Total Fee">₹ 81,400.00</td>
                    <td data-column="Paid">₹ 2,000.00</td>
                    <td data-column="Balance">₹ 79,400.00</td>
                  </tr>
                  <tr>
                    <td data-column="No:">7</td>
                    <td data-column="FeeTerm"><a href="#">OCTOBER</a></td>
                    <td data-column="Fee">₹ 8,77,300.00</td>
                    <td data-column="Adjustment">₹ 3,250.00</td>
                    <td data-column="Total Fee">₹ 75,300.00</td>
                    <td data-column="Paid">₹ 2,100.00</td>
                    <td data-column="Balance">₹ 73,200.00</td>
                  </tr>
                  <tr>
                    <td data-column="No:">8</td>
                    <td data-column="FeeTerm"><a href="#">NOVEMBER</a></td>
                    <td data-column="Fee">₹ 8,77,300.00</td>
                    <td data-column="Adjustment">₹ 1,000.00</td>
                    <td data-column="Total Fee">₹ 73,150.00</td>
                    <td data-column="Paid">₹ 2,100.00</td>
                    <td data-column="Balance">₹ 71,050.00</td>
                  </tr>
                  <tr>
                    <td data-column="No:">9</td>
                    <td data-column="FeeTerm"><a href="#">DECEMBER</a></td>
                    <td data-column="Fee">₹ 8,77,300.00</td>
                    <td data-column="Adjustment">₹ -900.00</td>
                    <td data-column="Total Fee">₹ 1,01,300.00</td>
                    <td data-column="Paid">₹ 0.00</td>
                    <td data-column="Balance">₹ 1,01,300.00</td>
                  </tr>
                  <tr>
                    <td data-column="No:">10</td>
                    <td data-column="FeeTerm"><a href="#">JANUARY</a></td>
                    <td data-column="Fee">₹ 8,77,300.00</td>
                    <td data-column="Adjustment">₹ 3,100.00</td>
                    <td data-column="Total Fee">₹ 77,500.00</td>
                    <td data-column="Paid">₹ 3,400.00</td>
                    <td data-column="Balance">₹ 74,100.00</td>
                  </tr>
                  <tr>
                    <td data-column="No:">11</td>
                    <td data-column="FeeTerm"><a href="#">FEBRUARY</a></td>
                    <td data-column="Fee">₹ 8,77,300.00</td>
                    <td data-column="Adjustment">₹ 1,850.00</td>
                    <td data-column="Total Fee">₹ 87,500.00</td>
                    <td data-column="Paid">₹ 3,500.00</td>
                    <td data-column="Balance">₹ 84,000.00</td>
                  </tr>
                  <tr>
                    <td data-column="No:">12</td>
                    <td data-column="FeeTerm"><a href="#">MARCH</a></td>
                    <td data-column="Fee">₹ 8,77,300.00</td>
                    <td data-column="Adjustment">₹ 3,750.00</td>
                    <td data-column="Total Fee">₹ 81,100.00</td>
                    <td data-column="Paid">₹ 1,800.00</td>
                    <td data-column="Balance">₹ 79,300.00</td>
                  </tr>
                </tbody>
              </table>
            </div>
             <div class="panel-footer">
               <div class="row">
                 <div class=" footer ng-binding "><a href="#" style="margin-right: 1%;">View More</a></div>
               </div>
             </div>
            </div> 
          </div>
        </div>
    
        <div class="col-lg-12 ">
          <div class="row tile-view">
    
           <!-- Termwise Balance Due (for all Classes) -->
           <div class="col-lg-6 tile-view">
     
            <div class="panel-heading hide-in-print">
              <div class="row">
                <div class="col-xs-6 heading ng-binding">Termwise Balance Due (for all Classes)</div>
                <div class="col-xs-6 right-align">
                  <span class="btn refresh-button" ng-click="RefreshWidget()">
                    <span class="glyphicon glyphicon-refresh" aria-hidden="true"><i class="fa-sharp fa-solid fa-rotate"></i></span>
                  </span>
                  <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                    <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-sharp fa-solid fa-print"></i></span>
                  </span>
                </div>
              </div>
            </div>
            <div class="table-content2">
             <table>
               <thead>
                 <tr>
                   <th>No:</th>
                   <th>Student</th>
                   <th>Parent</th>
                   <th>Class</th>
                   <th>Section</th>
                   <th>Contact no</th>
                   <th>Fee</th>
                   <th>Adjustment</th>
                   <th>Total Fee</th>
                   <th>Paid</th>
                   <th>Balance</th>
                 </tr>
               </thead>
               <tbody>
                 <tr>
                  <td data-column="No:">1</td>
                  <td data-column="Student"><a href="#">Simran Singh Singh</a></td>
                  <td data-column="Parent">Sri Nath Singh</td>
                  <td data-column="Class">IX</td>
                  <td data-column="Section">A</td>
                  <td data-column="Contact no">9798989011</td>
                  <td data-column="Fee">₹ 2,250.00</td>
                  <td data-column="Adjustment">₹ 650.00</td>
                  <td data-column="Total Fee">₹ 2,900.00</td>
                  <td data-column="Paid">₹ 0.00 </td>
                  <td data-column="Balance">₹ 2,900.00</td>
                 </tr>
                 <tr>
                  <td data-column="No:">2</td>
                  <td data-column="Student"><a href="#">Vivek Solanki</a></td>
                  <td data-column="Parent">Sarwajit Kumar</td>
                  <td data-column="Class">X</td>
                  <td data-column="Section">A</td>
                  <td data-column="Contact no">8789839639</td>
                  <td data-column="Fee">₹ 2,150.00</td>
                  <td data-column="Adjustment">₹ 650.00</td>
                  <td data-column="Total Fee">₹ 2,800.00</td>
                  <td data-column="Paid">₹ 0.00 </td>
                  <td data-column="Balance">₹ 2,800.00</td>
                 </tr>
                 <tr>
                  <td data-column="No:">3</td>
                  <td data-column="Student"><a href="#">Raj Narayan Singh</a></td>
                  <td data-column="Parent">Birbal</td>
                  <td data-column="Class">IX</td>
                  <td data-column="Section">A</td>
                  <td data-column="Contact no">9199691133</td>
                  <td data-column="Fee">₹ 2,150.00</td>
                  <td data-column="Adjustment">₹ 650.00</td>
                  <td data-column="Total Fee">₹ 2,800.00</td>
                  <td data-column="Paid">₹ 0.00</td>
                  <td data-column="Balance">₹ 2,800.00</td>
                 </tr>
                 <tr>
                  <td data-column="No:">4</td>
                  <td data-column="Student"><a href="#">Amit raj</a></td>
                  <td data-column="Parent">Birbal</td>
                  <td data-column="Class">X</td>
                  <td data-column="Section">A</td>
                  <td data-column="Contact no">9852515360</td>
                  <td data-column="Fee">₹ 2,150.00</td>
                  <td data-column="Adjustment">₹ 650.00</td>
                  <td data-column="Total Fee">₹ 2,800.00</td>
                  <td data-column="Paid">₹ 0.00</td>
                  <td data-column="Balance">₹ 2,800.00</td>
                 </tr>
                 <tr>
                  <td data-column="No:">5</td>
                  <td data-column="Student"><a href="#">SALONI KUMARI </a></td>
                  <td data-column="Parent">GAUTAM PD. SINGH</td>
                  <td data-column="Class">VIII</td>
                  <td data-column="Section">A</td>
                  <td data-column="Contact no">9973969550</td>
                  <td data-column="Fee">₹ 2,050.00</td>
                  <td data-column="Adjustment">₹ 650.00</td>
                  <td data-column="Total Fee">₹ 2,700.00</td>
                  <td data-column="Paid">₹ 0.00</td>
                  <td data-column="Balance">₹ 2,700.00</td>
                 </tr>
                 <tr>
                  <td data-column="No:">6</td>
                  <td data-column="Student"><a href="#">SIYA KUMARI</a></td>
                  <td data-column="Parent">RAJNIKANT KUMAR</td>
                  <td data-column="Class">VII</td>
                  <td data-column="Section">A</td>
                  <td data-column="Contact no">9334889914</td>
                  <td data-column="Fee">₹ 2,050.00</td>
                  <td data-column="Adjustment">₹ 650.00</td>
                  <td data-column="Total Fee">₹ 2,700.00</td>
                  <td data-column="Paid">₹ 0.00</td>
                  <td data-column="Balance">₹ 2,700.00</td>
                 </tr>
                 <tr>
                  <td data-column="No:">7</td>
                  <td data-column="Student"><a href="#">PRAGATI AHSAS</a></td>
                  <td data-column="Parent">Ajay Kumar varma</td>
                  <td data-column="Class">VII</td>
                  <td data-column="Section">A</td>
                  <td data-column="Contact no">7295949391</td>
                  <td data-column="Fee">₹ 2,050.00</td>
                  <td data-column="Adjustment">₹ 650.00</td>
                  <td data-column="Total Fee">₹ 2,700.00</td>
                  <td data-column="Paid">₹ 0.00</td>
                  <td data-column="Balance">₹ 2,700.00</td>
                 </tr>
                 <tr>
                  <td data-column="No:">8</td>
                  <td data-column="Student"><a href="#">Md. Intekhab Ahmad</a></td>
                  <td data-column="Parent">Md. Ishtiyaque Ahmad</td>
                  <td data-column="Class">IX</td>
                  <td data-column="Section">A</td>
                  <td data-column="Contact no">9097907755</td>
                  <td data-column="Fee">₹ 2,200.00</td>
                  <td data-column="Adjustment">₹ 500.00</td>
                  <td data-column="Total Fee">₹ 2,700.00</td>
                  <td data-column="Paid">₹ 0.00</td>
                  <td data-column="Balance">₹ 2,700.00</td>
                 </tr>
                 <tr>
                  <td data-column="No:">9</td>
                  <td data-column="Student"><a href="#">Vimal Kishor</a></td>
                  <td data-column="Parent">Vidyanand Kishor</td>
                  <td data-column="Class">IX</td>
                  <td data-column="Section">A</td>
                  <td data-column="Contact no">8873614801</td>
                  <td data-column="Fee">₹ 2,200.00</td>
                  <td data-column="Adjustment">₹ 500.00</td>
                  <td data-column="Total Fee">₹ 2,700.00</td>
                  <td data-column="Paid">₹ 0.00</td>
                  <td data-column="Balance">₹ 2,700.00</td>
                 </tr>
                 <tr>
                  <td data-column="No:">10</td>
                  <td data-column="Student"><a href="#">Manish Kumar</a></td>
                  <td data-column="Parent">Sachin Kumar</td>
                  <td data-column="Class">X</td>
                  <td data-column="Section">A</td>
                  <td data-column="Contact no">9473376476</td>
                  <td data-column="Fee">₹ 2,600.00</td>
                  <td data-column="Adjustment">₹ 100.00</td>
                  <td data-column="Total Fee">₹ 2,700.00</td>
                  <td data-column="Paid">₹ 0.00</td>
                  <td data-column="Balance">₹ 2,700.00</td>
                 </tr>
                 
               </tbody>
             </table>
           </div>
            <div class="panel-footer">
              <div class="row">
                <div class=" footer ng-binding "><a href="#" style="margin-right: 1%;">View More</a></div>
              </div>
            </div>
           </div> 
    
          <!-- Sectionwise Attendance Summary -->
          <div class="col-lg-3 tile-view">
    
            <div class="panel-heading hide-in-print">
              <div class="row">
                <div class="col-lg-8 heading ng-binding">Sectionwise Attendance Summary</div>
                <div class="col-lg-4 right-align">
                  <span class="btn refresh-button" ng-click="RefreshWidget()">
                    <span class="glyphicon glyphicon-refresh" aria-hidden="true"><i class="fa-sharp fa-solid fa-rotate"></i></span>
                  </span>
                  <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                    <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-sharp fa-solid fa-print"></i></span>
                  </span>
                  <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                    <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-solid fa-envelope"></i></span>
                  </span>
                </div>
              </div>
            </div>
            <div class="table-content2">
            <table>
              <thead>
                <tr>
                  <th>No:</th>
                  <th>Class</th>
                  <th>Section</th>
                  <th>Total</th>
                  <th>Absent</th>
                  <th>Present</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-column="No:">1</td>
                  <td data-column="Class"><a href="#">I</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">47</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">47</td>
                </tr>
                <tr>
                  <td data-column="No:">2</td>
                  <td data-column="Class"><a href="#">II</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">39</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">39</td>
                </tr>
                <tr>
                  <td data-column="No:">3</td>
                  <td data-column="Class"><a href="#">III</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">50</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">50</td>
                </tr>
                <tr>
                  <td data-column="No:">4</td>
                  <td data-column="Class"><a href="#">IV</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">45</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">45</td>
                </tr>
                <tr>
                  <td data-column="No:">5</td>
                  <td data-column="Class"><a href="#">V</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">48</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">48</td>
                </tr>
                <tr>
                  <td data-column="No:">6</td>
                  <td data-column="Class"><a href="#">VI</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">61</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">61</td>
                </tr>
                <tr>
                  <td data-column="No:">7</td>
                  <td data-column="Class"><a href="#">VII</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">47</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">47</td>
                </tr>
                <tr>
                  <td data-column="No:">8</td>
                  <td data-column="Class"><a href="#">VIII</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">41</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">41</td>
                </tr>
                <tr>
                  <td data-column="No:">9</td>
                  <td data-column="Class"><a href="#">IX</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">37</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">37</td>
                </tr>
                <tr>
                  <td data-column="No:">10</td>
                  <td data-column="Class"><a href="#">X</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">28</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">28</td>
                </tr>
                <tr>
                  <td data-column="No:">11</td>
                  <td data-column="Class"><a href="#">NURSERY</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">25</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">25</td>
                </tr>
                <tr>
                  <td data-column="No:">12</td>
                  <td data-column="Class"><a href="#">L.KG</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">36</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">36</td>
                </tr>
                <tr>
                  <td data-column="No:">13</td>
                  <td data-column="Class"><a href="#">U.KG</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">37</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">37</td>
                </tr>
                <tr>
                  <td data-column="No:">14</td>
                  <td data-column="Class"><a href="#">XI</a></td>
                  <td data-column="Section"><a href="#">A</a></td>
                  <td data-column="Total">4</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">4</td>
                </tr>
                <tr>
                  <td data-column="No:">15</td>
                  <td data-column="Class"><a href="#">XII</a></td>
                  <td data-column="Section">7<a href="#">A</a></td>
                  <td data-column="Total"></td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">7</td>
                </tr>
                
              </tbody>
            </table>
          </div>
            <div class="panel-footer">
              <div class="row">
                <div class=" footer ng-binding "><a href="#" style="margin-right: 1%;">View More</a></div>
              </div>
            </div>
          </div>
    
          <!-- Classwise Attendance Summary -->
          <div class="col-lg-3 tile-view">
    
            <div class="panel-heading hide-in-print">
              <div class="row">
                <div class="col-lg-8 heading ng-binding">Classwise Attendance Summary</div>
                <div class="col-lg-4 right-align">
                  <span class="btn refresh-button" ng-click="RefreshWidget()">
                    <span class="glyphicon glyphicon-refresh" aria-hidden="true"><i class="fa-sharp fa-solid fa-rotate"></i></span>
                  </span>
                  <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                    <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-sharp fa-solid fa-print"></i></span>
                  </span>
                </div>
              </div>
            </div>
            <div class="table-content2">
            <table>
              <thead>
                <tr>
                  <th>No:</th>
                  <th>Class</th>
                  <th>Total</th>
                  <th>Absent</th>
                  <th>Present</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-column="No:">1</td>
                  <td data-column="Class"><a href="#">I</a></td>
                  <td data-column="Total">25</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">25</td>
                </tr>
                <tr>
                  <td data-column="No:">2</td>
                  <td data-column="Class"><a href="#">II</a></td>
                  <td data-column="Total">36</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">36</td>
                </tr>
                <tr>
                  <td data-column="No:">3</td>
                  <td data-column="Class"><a href="#">III</a></td>
                  <td data-column="Total">37</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">37</td>
                </tr>
                <tr>
                  <td data-column="No:">4</td>
                  <td data-column="Class"><a href="#">IV</a></td>
                  <td data-column="Total">47</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">47</td>
                </tr>
                <tr>
                  <td data-column="No:">5</td>
                  <td data-column="Class"><a href="#">V</a></td>
                  <td data-column="Total">39</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">39</td>
                </tr>
                <tr>
                  <td data-column="No:">6</td>
                  <td data-column="Class"><a href="#">VI</a></td>
                  <td data-column="Total">50</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">50</td>
                </tr>
                <tr>
                  <td data-column="No:">7</td>
                  <td data-column="Class"><a href="#">VII</a></td>
                  <td data-column="Total">45</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">45</td>
                </tr>
                <tr>
                  <td data-column="No:">8</td>
                  <td data-column="Class"><a href="#">VIII</a></td>
                  <td data-column="Total">48</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">48</td>
                </tr>
                <tr>
                  <td data-column="No:">9</td>
                  <td data-column="Class"><a href="#">IX</a></td>
                  <td data-column="Total">61</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">61</td>
                </tr>
                <tr>
                  <td data-column="No:">10</td>
                  <td data-column="Class"><a href="#">X</a></td>
                  <td data-column="Total">47</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">47</td>
                </tr>
                <tr>
                  <td data-column="No:">11</td>
                  <td data-column="Class"><a href="#">NURSERY</a></td>
                  <td data-column="Total">41</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">41</td>
                </tr>
                <tr>
                  <td data-column="No:">12</td>
                  <td data-column="Class"><a href="#">L.KG</a></td>
                  <td data-column="Total">37</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">37</td>
                </tr>
                <tr>
                  <td data-column="No:">13</td>
                  <td data-column="Class"><a href="#">U.KG</a></td>
                  <td data-column="Total">28</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">28</td>
                </tr>
                <tr>
                  <td data-column="No:">14</td>
                  <td data-column="Class"><a href="#">XI</a></td>
                  <td data-column="Total">4</td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">4</td>
                </tr>
                <tr>
                  <td data-column="No:">15</td>
                  <td data-column="Class"><a href="#">XII</a></td>
                  <td data-column="Total"></td>
                  <td data-column="Absent">0</td>
                  <td data-column="Present">7</td>
                </tr>
                
              </tbody>
            </table>
          </div>
            <div class="panel-footer">
              <div class="row">
                <div class=" footer ng-binding "><a href="#" style="margin-right: 1%;">View More</a></div>
              </div>
            </div>
          </div>
          </div>
        </div>
    
        <div class="col-lg-12 ">
          <div class="row tile-view">
    
          <!-- Head Count -->
          <div class="col-lg-3 tile-view">
    
            <div class="panel-heading hide-in-print">
              <div class="row">
                <div class="col-lg-8 heading ng-binding">Head Count</div>
                <div class="col-lg-4 right-align">
                  <span class="btn refresh-button" ng-click="RefreshWidget()">
                    <span class="glyphicon glyphicon-refresh" aria-hidden="true"><i class="fa-sharp fa-solid fa-rotate"></i></span>
                  </span>
                  <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                    <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-sharp fa-solid fa-print"></i></span>
                  </span>
                </div>
              </div>
            </div>
            <div class="table-content3">
            <table>
              <thead>
                <tr>
                  <th>No:</th>
                  <th>Type</th>
                  <th>Strength</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-column="No:">1</td>
                  <td data-column="Type"><a href="#">Student</a></td>
                  <td data-column="Strength"><a href="#">553</a></td>
                </tr>
                <tr>
                  <td data-column="No:">2</td>
                  <td data-column="Type"><a href="#">Staff</a></td>
                  <td data-column="Strength"><a href="#">47</a></td>
                </tr>
                <tr>
                  <td data-column="No:">3</td>
                  <td data-column="Type"><a href="#">New Students Admitted In Academic Year </a></td>
                  <td data-column="Strength"><a href="#">0</a></td>
                </tr>
                
              </tbody>
            </table>
          </div>
            <div class="panel-footer">
              <div class="row">
                <div class=" footer ng-binding "><a href="#" style="margin-right: 1%;">View More</a></div>
              </div>
            </div>
          </div>
    
          <!-- Transport Summary -->
          <div class="col-lg-2 tile-view">
    
            <div class="panel-heading hide-in-print">
              <div class="row">
                <div class="col-lg-8 heading ng-binding">Transport Summary</div>
                <div class="col-lg-4 right-align">
                  <span class="btn refresh-button" ng-click="RefreshWidget()">
                    <span class="glyphicon glyphicon-refresh" aria-hidden="true"><i class="fa-sharp fa-solid fa-rotate"></i></span>
                  </span>
                  <!-- <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                    <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-sharp fa-solid fa-print"></i></span>
                  </span> -->
                </div>
              </div>
            </div>
            <div class="table-content3">
            <table>
              
              <tbody>
                <tr>
                  <td>No data available</td>
                </tr>
                
              </tbody>
            </table>
          </div>
            <div class="panel-footer">
              <div class="row">
                <div class=" footer ng-binding "><a href="#" style="margin-right: 1%;">View More</a></div>
              </div>
            </div>
          </div>
    
    
           <!-- Upcoming Exams -->
           <div class="col-lg-7 tile-view">
     
            <div class="panel-heading hide-in-print">
              <div class="row">
                <div class="col-xs-6 heading ng-binding">Upcoming Exams</div>
                <div class="col-xs-6 right-align">
                  <span class="btn refresh-button" ng-click="RefreshWidget()">
                    <span class="glyphicon glyphicon-refresh" aria-hidden="true"><i class="fa-sharp fa-solid fa-rotate"></i></span>
                  </span>
                  <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                    <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-sharp fa-solid fa-print"></i></span>
                  </span>
                </div>
              </div>
            </div>
            <div class="table-content3">
             <table>
               <thead>
                 <tr>
                   <th>No:</th>
                   <th>Exam Date</th>
                   <th>Exam Type</th>
                   <th>Subject</th>
                   <th>Class</th>
                   <th>Exam Time</th>
                   <th>Duration</th>
                   <th>Max Marks</th>
                 </tr>
               </thead>
               <tbody>
                 <tr>
                  <td data-column="No:">1</td>
                  <td data-column="Exam Date">13/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">MATH</td>
                  <td data-column="Class">I</td>
                  <td data-column="Exam Time">09:00 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">2</td>
                  <td data-column="Exam Date">13/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">ENGLISH-II ( Literature )</td>
                  <td data-column="Class">II</td>
                  <td data-column="Exam Time">09:00 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">3</td>
                  <td data-column="Exam Date">13/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">Drawing</td>
                  <td data-column="Class">II</td>
                  <td data-column="Exam Time">11:40 AM</td>
                  <td data-column="Duration"> 60</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">4</td>
                  <td data-column="Exam Date">14/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">GK</td>
                  <td data-column="Class">II</td>
                  <td data-column="Exam Time">11:40 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">5</td>
                  <td data-column="Exam Date">14/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">HINDI</td>
                  <td data-column="Class">II</td>
                  <td data-column="Exam Time">09:00 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">6</td>
                  <td data-column="Exam Date">14/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">ENGLISH-I ( Language )</td>
                  <td data-column="Class">I</td>
                  <td data-column="Exam Time">09:00 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">7</td>
                  <td data-column="Exam Date">15/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">COMPUTER</td>
                  <td data-column="Class">I</td>
                  <td data-column="Exam Time">09:00 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">8</td>
                  <td data-column="Exam Date">13/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">M.SC</td>
                  <td data-column="Class">I</td>
                  <td data-column="Exam Time">11:40 PM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">9</td>
                  <td data-column="Exam Date">15/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">SCIENCE</td>
                  <td data-column="Class">II</td>
                  <td data-column="Exam Time">09:00 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">10</td>
                  <td data-column="Exam Date">15/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">M.SC</td>
                  <td data-column="Class">II</td>
                  <td data-column="Exam Time">11:40 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">11</td>
                  <td data-column="Exam Date">16/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">ENGLISH-I ( Language )</td>
                  <td data-column="Class">II</td>
                  <td data-column="Exam Time"> 09:00 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">12</td>
                  <td data-column="Exam Date">17/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">HINDI</td>
                  <td data-column="Class">I</td>
                  <td data-column="Exam Time">09:00 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">13</td>
                  <td data-column="Exam Date">18/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">EVS</td>
                  <td data-column="Class">II</td>
                  <td data-column="Exam Time">09:00 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">14</td>
                  <td data-column="Exam Date">19/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">COMPUTER</td>
                  <td data-column="Class">II</td>
                  <td data-column="Exam Time">09:00 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">15</td>
                  <td data-column="Exam Date">19/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">SCIENCE</td>
                  <td data-column="Class">I</td>
                  <td data-column="Exam Time">09:00 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">16</td>
                  <td data-column="Exam Date">20/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">ENGLISH-II ( Literature )</td>
                  <td data-column="Class">I</td>
                  <td data-column="Exam Time">09:00 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 <tr>
                  <td data-column="No:">17</td>
                  <td data-column="Exam Date">20/12/2022</td>
                  <td data-column="Exam Type">2ND TERMINAL</td>
                  <td data-column="Subject">MATH</td>
                  <td data-column="Class">II</td>
                  <td data-column="Exam Time">09:00 AM</td>
                  <td data-column="Duration"> 120</td>
                  <td data-column="Max Marks">100</td>
                 </tr>
                 
               </tbody>
             </table>
           </div>
            <div class="panel-footer">
              <div class="row">
                <div class=" footer ng-binding "><a href="#" style="margin-right: 1%;">View More</a></div>
              </div>
            </div>
           </div> 
    
    
          </div>
        </div>
    
        <div class="col-lg-12 ">
          <div class="row tile-view">
    
    
          <!-- Upcoming Events -->
          <div class="col-lg-2 tile-view">
    
            <div class="panel-heading hide-in-print">
              <div class="row">
                <div class="col-lg-8 heading ng-binding">Upcoming Events</div>
                <div class="col-lg-4 right-align">
                  <span class="btn refresh-button" ng-click="RefreshWidget()">
                    <span class="glyphicon glyphicon-refresh" aria-hidden="true"><i class="fa-sharp fa-solid fa-rotate"></i></span>
                  </span>
                  <!-- <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                    <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-sharp fa-solid fa-print"></i></span>
                  </span> -->
                </div>
              </div>
            </div>
            <div class="table-content4">
            <table>
              
              <tbody>
                <tr>
                  <td>No data available</td>
                </tr>
                
              </tbody>
            </table>
          </div>
            <div class="panel-footer">
              <div class="row">
                <div class=" footer ng-binding "><a href="#" style="margin-right: 1%;">View More</a></div>
              </div>
            </div>
          </div>
    
          <!-- Upcoming Holidays -->
          <div class="col-lg-2 tile-view">
    
            <div class="panel-heading hide-in-print">
              <div class="row">
                <div class="col-lg-8 heading ng-binding">Upcoming Holidays</div>
                <div class="col-lg-4 right-align">
                  <span class="btn refresh-button" ng-click="RefreshWidget()">
                    <span class="glyphicon glyphicon-refresh" aria-hidden="true"><i class="fa-sharp fa-solid fa-rotate"></i></span>
                  </span>
                  <!-- <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                    <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-sharp fa-solid fa-print"></i></span>
                  </span> -->
                </div>
              </div>
            </div>
            <div class="table-content4">
            <table>
              
              <tbody>
                <tr>
                  <td>No data available</td>
                </tr>
                
              </tbody>
            </table>
          </div>
            <div class="panel-footer">
              <div class="row">
                <div class=" footer ng-binding "><a href="#" style="margin-right: 1%;">View More</a></div>
              </div>
            </div>
          </div>
    
          <!-- Birthday Students List -->
          <div class="col-lg-8 tile-view">
    
            <div class="panel-heading hide-in-print">
              <div class="row">
                <div class="col-lg-8 heading ng-binding">Birthday Students List</div>
                <div class="col-lg-4 right-align">
                  <span class="btn refresh-button" ng-click="RefreshWidget()">
                    <span class="glyphicon glyphicon-refresh" aria-hidden="true"><i class="fa-sharp fa-solid fa-rotate"></i></span>
                  </span>
                  <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                    <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-sharp fa-solid fa-print"></i></span>
                  </span>
                  <span class="btn print-button" ng-click="PrintWidget()" ng-show="WidgetColumns.length != 0">
                    <span class="glyphicon glyphicon-print" aria-hidden="true"><i class="fa-solid fa-envelope"></i></span>
                  </span>
                </div>
              </div>
            </div>
            <div class="table-content4">
            <table>
              <thead>
                <tr>
                  <th>No:</th>
                  <th>Student</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td data-column="No:">1</td>
                  <td data-column="Student"><a href="#">Simran Singh Singh</a></td>
                </tr>
                <tr>
                  <td data-column="No:">2</td>
                  <td data-column="Student"><a href="#">Ayush kumar</a></td>
                </tr>
                <tr>
                  <td data-column="No:">3</td>
                  <td data-column="Student"><a href="#">Aarushi kumari</a></td>
                </tr>
                <tr>
                  <td data-column="No:">4</td>
                  <td data-column="Student"><a href="#">Sakshi Kumari</a></td>
                </tr>
                <tr>
                  <td data-column="No:">5</td>
                  <td data-column="Student"><a href="#">	badal kumar</a></td>
                </tr>
                
              </tbody>
            </table>
          </div>
            <div class="panel-footer">
              <div class="row">
                <div class=" footer ng-binding "><a href="#" style="margin-right: 1%;">View More</a></div>
              </div>
            </div>
          </div>
    
    
    
          </div>
        </div>
      </div>
  
      <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js"></script>
      <script>
        const TileView = document.getElementById("tile-content");
const GrideView = document.getElementById("gride-content");
const Tilebtn = document.getElementById("tilebtn");
const Gridebtn = document.getElementById("gridebtn");
Tilebtn.onclick = function() {
	if (GrideView.style.display !== "none") {
		GrideView.style.display = "none";
		TileView.style.display = "block";
	} else {
		GrideView.style.display = "none";
	}
};

Gridebtn.onclick = function() {
	if (TileView.style.display !== "none") {
		TileView.style.display = "none";
		GrideView.style.display = "block";
	} else {
		TileView.style.display = "none";
	}
};
      </script>
</body>
</html>
