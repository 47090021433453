import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  success(arg0: string) {
    throw new Error('Method not implemented.');
  }

  constructor(
    private toastr: ToastrService
  ) { }

  showSuccess(title: string, description: string,) {
    this.toastr.success(description, title,{
      enableHtml:true
    });
  }

  showError(title: string, description: string,) {
    this.toastr.error(description, title,{
      enableHtml:true
    });
  }
  
  showWarning(title: string, description: string,) {
    this.toastr.warning(description, title);
  }

  showInfo(title: string, description: string,) {
    this.toastr.info(description, title);
  }

}
