import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router,NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { AcademicYear,Modules } from 'src/app/models/shared-model';
import { ConfigurationService } from 'src/app/services/configurations/ConfigurationService.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { filter } from 'rxjs/operators';
import { ModuleService } from 'src/app/services/modules-sub/module.service';
import { PermissionDto } from 'src/app/models/shared-model';
import { StudentService } from 'src/app/services/user/student.service';

@Component({
  selector: 'app-navbar-layout',
  templateUrl: './navbar-layout.component.html',
  styleUrls: ['./navbar-layout.component.scss']
})
export class NavbarLayoutComponent implements OnInit {
  AcademicYears: AcademicYear[] = [];
  modules : Modules[] = [];
  currentRouteName: string;
  splitSentence:string;
  selectedValue: string;
  selectedText: string;
  permission: PermissionDto[] = [];
  allowedModules: string[] = [];
  constructor(private router: Router, private auth: AuthService,
    private configuration: ConfigurationService,
    private sharedservice: SharedService,
    private moduleservice:ModuleService,
    private cdRef: ChangeDetectorRef,
    private studentService: StudentService) { }

  ngOnInit(): void {
   this.GetAcademicYears();
   //this.GetAllModules();
   this.getModulesAndSubmodules();
  // Option 1: Get route name from the full URL
  this.currentRouteName = this.getCurrentRouteNameFromUrl();

  // Option 2: Listen for route navigation events
  this.listenToRouteChanges();

  }
  onDropdownChange(event: any) {
    this.selectedValue = event.target.value;
    this.selectedText = event.target.options[event.target.selectedIndex].text;
    this.sharedservice.changeMessage(this.selectedValue);
    this.sharedservice.changeTextMessage(this.selectedText);
    this.sharedservice.callMethod();
    //alert(this.router.url)
    // if(this.router.url.includes('quickadmissionmanagement')){
    //   //alert('true')
    //   let studentquery = {
    //     username: '',
    //     name: '',
    //     smsContact: '',
    //     classId: '00000000-0000-0000-0000-000000000000',
    //     sectionId: '00000000-0000-0000-0000-000000000000',
    //     rollNo: '',
    //     academicYearId: this.selectedValue == '' ? '00000000-0000-0000-0000-000000000000' : this.selectedValue
    //   };

    //   this.studentService.getstudent(studentquery).subscribe(
    //     {
    //       next: (result: any) => {
    //         //this.admissions = result;
    //        // console.log(this.admissions)
    //       }
    //     }
    //   )
    //}
    // else{
    //   alert('false')
    // }
  }
  // hasPermission(permission: any): boolean {
  //   debugger
  //   return this.permission.some(p => p.name.includes(permission));
  // }


  checkPermission(submoduleName: any, permissions: PermissionDto[]): boolean {
    // Convert the submodule name to the format used in the permissions
    const formattedSubmoduleName = 'Permissions.' + submoduleName.charAt(0).toUpperCase() + submoduleName.slice(1);

    // Ensure that permissions is defined before attempting to use the map method
    const formattedPermissions = permissions ? permissions.map(permission => permission.toString().replace(/\.[^.]+$/, '')) : [];

    // Check if the formatted submodule name is included in the permissions array
    const hasPermission = formattedPermissions.includes(formattedSubmoduleName);

    return hasPermission;
  }

  getModulesAndSubmodules() {
    // Load permissions from local storage
    const storedPermissions = localStorage.getItem('permissions');
    const permissions: PermissionDto[] = storedPermissions ? JSON.parse(storedPermissions) : [];

    this.moduleservice.getModules().subscribe(modules => {
      this.modules = modules;

      // Once modules are fetched, fetch submodules for each module
      this.modules.forEach(module => {
        this.moduleservice.getSubModules(module.id).subscribe(submodules => {
          // Filter submodules based on permissions
          module.submodules = submodules.filter(submodule => this.checkPermission(submodule.description, permissions));
          this.cdRef.detectChanges();
        });
      });
    });
  }


  GetAcademicYears(){
    this.configuration.GetAcademicYear().subscribe(
      (result: any) => {
        this.AcademicYears = result.data
        this.sharedservice.changeMessage(this.AcademicYears[0].id);
        this.sharedservice.changeTextMessage(this.AcademicYears[0].name);
      })
  }
  // GetAllModules(){
  //   this.sharedservice.getModules().subscribe(
  //     (result: any) => {
  //       this.modules = result.data
  //     })
  // }

  logout() {
    this.auth.logout();
  }

  private getCurrentRouteNameFromUrl(): string {
    // Get the current URL
    const currentUrl = this.router.url;

    if (currentUrl.match(/\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i)) {
      return ''; // Return empty for URLs ending with a GUID
    }
    // Check if the current URL corresponds to the dashboard
    if (currentUrl.includes('/dashboard')) {
      return 'Dashboard';
    }
    if (currentUrl.includes('/profile-setting')) {
      return 'Profile Setting';
    }
    if (currentUrl.includes('/change-password')) {
      return 'Change Password';
    }

    // Iterate through modules and submodules to find a match with the current URL
    for (const module of this.modules) {
      if (module.submodules) {
        for (const submodule of module.submodules) {
          // Check if the submodule description matches the current URL
          if (currentUrl.includes(submodule.description)) {
            console.log(submodule.name);
            return submodule.name;
          }
        }
      }
    }

    // If no match is found, return the component's route name based on the route path
    const routePath = currentUrl.split('/').pop(); // Get the last part of the URL
    return routePath.charAt(0).toUpperCase() + routePath.slice(1);
  }



  private listenToRouteChanges(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // Update the currentRouteName when a navigation event occurs
        this.currentRouteName = this.getCurrentRouteNameFromUrl();
      });
  }

}
