import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtService } from '../jwt/jwt.service';
import { Router } from '@angular/router';
import { BaseService } from '../../base.service';
import { GetUserProfile } from '../../models/shared-model';

@Injectable({
  providedIn: 'root'
})
export class SharedService extends BaseService {

  userProfile: GetUserProfile = new GetUserProfile();

  private currentUserSubject = new BehaviorSubject<any>({});
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  private isAdminSubject = new ReplaySubject<boolean>(1);
  public isAdmin = this.isAdminSubject.asObservable();

  private messageSource = new BehaviorSubject<string>('');
  currentMessage = this.messageSource.asObservable();

  private messageSourceText = new BehaviorSubject<string>('');
  currentMessageText = this.messageSourceText.asObservable();

  private methodCallSource = new Subject<any>();
  methodCalled$ = this.methodCallSource.asObservable();

  
 
  public redirectUrl = '';

  public reason = '';
  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private router: Router
  ) {
    super();
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }
  changeTextMessage(messageText: string) {
    this.messageSourceText.next(messageText);
  }
  callMethod() {
    this.methodCallSource.next();
  }
  setReason(data: any) {
    this.reason = data;
  }
  getReason() {
    return this.reason
  }
  getUserByToken() {
    return this.http.get<any>(this.httpSource + "User/GetUserDetail");
  }

  populate() {
    // return false
    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getToken() && typeof this.jwtService.getToken() !== 'undefined') {
      this.getUserByToken().subscribe(
        res => {
          if (res['statusCode'] == 200) {
            let data = res['data'];
            this.setAuth(data);
            if (this.redirectUrl) {
              this.router.navigate([this.redirectUrl]);
              this.redirectUrl = '';
            } else if (this.router.url == '/id/login') {
              var role = data.userRole.roleId;
              switch (role.toLowerCase()) {
                case "525aa6e8-daa5-11ec-9d64-0242ac120002": {
                  this.router.navigate(['/telesales']);
                  break;
                }
                case "f2459c04-daa4-11ec-9d64-0242ac120002": {
                  this.router.navigate(['/headoffice']);
                  break;
                }
                case "0544fad4-daa5-11ec-9d64-0242ac120002": {
                  this.router.navigate(['/dm']);
                  break;
                }
                case "68e73ec6-daa5-11ec-9d64-0242ac120002": {
                  this.router.navigate(['/accountant']);
                  break;
                }
                case "26d1fc24-daa5-11ec-9d64-0242ac120002": {
                  this.router.navigate(["/tm"]);
                  break;
                }
                default: {
                  this.router.navigate(['/headoffice']);
                  break;
                }
              }
            } else {
              // Logic here for else case right now dont have any
            }
          }
        },
        err => this.purgeAuth()
      );
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }


  setAuth(user: GetUserProfile) {
    // console.log(user,'user')
    // Set current user data into observable
    this.currentUserSubject.next(user);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);
    // Set isAdmin as per role
    this.isAdminSubject.next(true);
  }


  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next({});
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    // Set Admin to false
    this.isAdminSubject.next(false);
  }

  getModules() {
    return this.http.get<any>(this.httpSource + "v1/module/getallmodule");
  }

  getSubModules(id: string) {
    return this.http.get<any>(this.httpSource + "v1/submodule/" + id + "/getsubmodulebymoduleid");
  }

}
