import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fees-layout',
  templateUrl: './fees-layout.component.html',
  styleUrls: ['./fees-layout.component.scss']
})
export class FeesLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
