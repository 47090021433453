import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';
import { AccessDenialComponent } from './core/shared/components/access-denial/access-denial.component';
import { NotFoundComponent } from './core/shared/components/not-found/not-found.component';
import { ServerErrorComponent } from './core/shared/components/server-error/server-error.component';
import { AcademicPlannerLayoutComponent } from './layouts/academic-planner-layout/academic-planner-layout.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ConfigurationLayoutComponent } from './layouts/configuration-layout/configuration-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { OperationsLayoutComponent } from './layouts/operations-layout/operations-layout.component';
import { SecurityLayoutComponent } from './layouts/security-layout/security-layout.component';
import { UserLayoutComponent } from './layouts/user-layout/user-layout.component';
import { FeesLayoutComponent } from './layouts/fees-layout/fees-layout.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';

const routes: Routes = [
  {
    path: '', redirectTo: 'login', pathMatch: 'full'
  },
  {
    path: 'login',
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then(mod => mod.AuthModule),
  },

  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: DashboardLayoutComponent,
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(mod => mod.DashboardModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    loadChildren: () => import('./modules/admin/admin.module').then(mod => mod.AdminModule)
  },
  {
    path: 'configurations',
    canActivate: [AuthGuard],
    component: ConfigurationLayoutComponent,
    loadChildren: () => import('./modules/configurations/configurations.module').then(mod => mod.ConfigurationsModule)
  },
  {
    path: 'operations',
    canActivate: [AuthGuard],
    component: OperationsLayoutComponent,
    loadChildren: () => import('./modules/operations/operations.module').then(mod=>mod.OperationsModule)
  },
  {
    path: 'academicplanner',
    canActivate: [AuthGuard],
    component: AcademicPlannerLayoutComponent,
    loadChildren: () => import('./modules/academic-planner/academic-planner.module').then(mod=>mod.AcademicPlannerModule)
  },
  {
    path: 'security',
    canActivate: [AuthGuard],
    component: SecurityLayoutComponent,
    loadChildren: () => import('./modules/security/security.module').then(mod=>mod.SecurityModule)
  },
  {
     path:'fees',
     canActivate:[AuthGuard],
     component:FeesLayoutComponent,
     loadChildren:()=> import('./modules/Fees/fees.module').then(mod=>mod.FeesModule)
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    component: UserLayoutComponent,
    loadChildren: () => import('./modules/users/users.module').then(mod=>mod.UsersModule)
  },
  {
    path: 'access-denial', component: AccessDenialComponent
  },
  
  {
    path: 'not-found', component: NotFoundComponent
  },
  {
    path:'forget-password', component:ForgetPasswordComponent
  },
  {
    path: 'server-error', component: ServerErrorComponent
  },
  {
    path: '**', redirectTo: 'not-found', pathMatch: 'full'
  },

  
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
