// module.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Modules, SubModules } from 'src/app/models/shared-model';
import { SharedService } from '../shared/shared.service';

@Injectable({
  providedIn: 'root',
})
export class ModuleService {
  private apiUrl = 'https://localhost:5001/api/'; // Replace with your actual API endpoint
  private modules: Modules[] | null = null;
  private submodulesCache: { [id: string]: SubModules[] } = {};

  constructor(private http: HttpClient, private sharedService: SharedService) {}

  getModules(): Observable<Modules[]> {
    const storedModules = localStorage.getItem('modules');
    if (storedModules) {
      this.modules = JSON.parse(storedModules);
      return of(this.modules);
    } else {
      return this.sharedService.getModules().pipe(
        switchMap((modules) => {
          this.modules = modules.data;
          localStorage.setItem('modules', JSON.stringify(this.modules));
          return of(this.modules);
        })
      );
    }
  }

  getSubModules(id: string): Observable<SubModules[]> {
    const storedSubmodules = localStorage.getItem(`submodules-${id}`);
    if (storedSubmodules) {
      this.submodulesCache[id] = JSON.parse(storedSubmodules);
      return of(this.submodulesCache[id]);
    } else {
      return this.sharedService.getSubModules(id).pipe(
        switchMap((submodules) => {
          this.submodulesCache[id] = submodules.data; // Adjust here
          localStorage.setItem(`submodules-${id}`, JSON.stringify(this.submodulesCache[id])); // Adjust here
          return of(this.submodulesCache[id]);
        })
      );
    }
  }
}
