import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseService 
{ 
  httpSource: string;
  noApiHttpSource: string;

  arrayBufferOptions: Object = {
      responseType: 'arraybuffer',
      observe: 'response'
  }

  constructor() {
      this.httpSource = environment.apiUrl;
   


  }
}
