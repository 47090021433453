<!DOCTYPE html>
<html lang="en">


<meta http-equiv="content-type" content="text/html;charset=utf-8" />

<head>
    <meta charset="utf-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="" />
    <meta name="keywords" content="" />
    <title></title>

    <!-- Bootstrap -->
    <link href="/assets/boot/bootstrap.min.css" rel="stylesheet" type="text/css" />
    <link href="/assets/css/toastr.css" rel="stylesheet" type="text/css" />
    <script src="js/core/jquery-2.2.0.min.js" type="text/javascript"></script>
    <script src="js/core/jquery.tmpl.min.js" type="text/javascript"></script>
    <script src="js/core/spt.all59bc.js?v=22.4.124.2" type="text/javascript"></script>
    <script src="js/bootstrap.min.js" type="text/javascript"></script>
    <script src="js/toastr/toastr.min.js" type="text/javascript"></script>
    <script src="js/spt.json59bc.js?v=22.4.124.2"></script>

    <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600" rel="stylesheet">
    <link href="/assets/css/font-awesome.min.css" rel="stylesheet" />
    <link href="/assets/css/font-awesome.min.css" rel="stylesheet" type="text/css" />
    <link href="/assets/css/loginPage_v2.css" rel="stylesheet" />


    <script src="js/core/ng/angular.min.js" type="text/javascript"></script>
    <script src="js/core/ng/angular-moment.min.js"></script>
    <script src="js/App/services59bc.js?v=22.4.124.2"></script>



</head>

<body>
    <section id="fa_pricing" ng-app="forgotPasswordApp">
        <div class="container" ng-controller="forgotPasswordController">
           <!-- Import ReactiveFormsModule in your Angular module -->
<form [formGroup]="forgotPasswordForm">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <div id="loginwrapper" class="main_pricing_conatiner green_pricing wow fadeInUpBig" data-wow-delay="0.6s"
          style="visibility: visible; animation-delay: 0.6s; animation-name: fadeInUp; padding-bottom:80px!important;">
  
          <div class="price loin">
            <h2>Forgot Password</h2>
            <div class="schoollogo">
              <img src="assets/images/logo.png" id="SchoolLogo" class="" alt="schoollogo">
            </div>
          </div>
  
          <div>
            <a href="#/login" style="padding-left:5px" class="forgetpass">
              <i class="fa fa-arrow-circle-left"></i> Go to Login
            </a>
          </div>
  
          <div class="price_listing">
            <div>
              <div class="form-group">
                <input type="text" class="form-control" formControlName="email" placeholder="Email Address" required/>
                <!-- <div *ngIf="forgotPasswordForm.get('forgotPasswordForm.email').hasError('required') && forgotPasswordForm.get('forgotPasswordForm.email').touched" class="text-danger">
                    Username is required.
                  </div> -->
                </div>
            </div>
          </div>
  
        
            <button type="submit" class="btn btn-success" id="btnGetUserPassword" (click)="submitForgotRequest()">
              
                Send me user id & password
              
            </button>
         
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </form>
  
        </div>
    </section>
</body>


</html>

