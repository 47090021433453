import { Component, OnInit } from '@angular/core';
import { UserApiService } from '../core/api/identity/user-api.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastService } from '../services/toast/toast.service';
@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  forgotPasswordForm: UntypedFormGroup;

  constructor(private userapi: UserApiService,private fb: UntypedFormBuilder,private toastrService: ToastService) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      
        email: ['', Validators.required]
    
    });
  }

  submitForgotRequest(){
    this.userapi.forgotPassword(this.forgotPasswordForm.value).subscribe((result: any) => {
      
      this.toastrService.showSuccess("Success","Forgot password request sent successfully!")
    this.forgotPasswordForm.reset();
  })
  }
}
